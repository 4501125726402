import axios from 'axios';
import Bien from '../../models/Bien';
import domain from '@/environment';
let requestGetBien = null;

const state = {
  biens: [],
  loadingBien: false,
  errorBiens: [],
  filialesBien: [],
  totalRowsBien: 0
};
const getters = {
  biens: state => state.biens,
  loadingBien: state => state.loadingBien,
  errorBiens: state => state.errorBiens,
  totalRowsBien: state => state.totalRowsBien
};
const mutations = {
  SET_BIENS(state, payload) {
    state.biens = payload.map(bien => Bien.create(bien));
  },
  SET_LOADING_BIENS(state, payload) {
    state.loadingBien = payload;
  },
  SET_ERROR_BIENS(state, payload) {
    state.errorBiens = payload;
  },
  ADD_NEW_BIEN(state, payload) {
    state.biens.unshift(Bien.create(payload));
  },
  UPDATE_BLOCKED_BIEN(state, payload) {
    payload.item.update(payload.response);
  },
  UPDATE_BIEN(state, payload) {
    payload.item.update(payload.response);
  },
  SET_TOTAL_ROWS_BIEN(state, payload) {
    state.totalRowsBien = payload;
  }
};
const actions = {
  async getAllBiens({ commit }, payload) {
    commit('SET_LOADING_BIENS', true);
    let params = {};
    if (payload?.page != null) {
      params.page = payload.page;
    }
    if (payload?.per_page != null) {
      params.per_page = payload.per_page;
    }
    if (payload?.nom != null && payload?.nom != '') {
      params.nom = payload.nom;
    }
    try {
      if (requestGetBien) {
        requestGetBien.cancel('cancel-request');
      }
      requestGetBien = axios.CancelToken.source();
      const response = await axios.get(domain + '/api/biens/getAllBiens', {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: requestGetBien.token
      });
      if (payload?.per_page != null) {
        commit('SET_BIENS', response.data.data.data);
        commit('SET_TOTAL_ROWS_BIEN', response.data.data.total);
      } else {
        commit('SET_BIENS', response.data.data);
      }
      commit('SET_LOADING_BIENS', false);
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_BIENS', error.response.data);
      } else if (error.message !== 'cancel-request') {
        commit('SET_ERROR_BIENS', 'Une erreur est survenue');
      }
      commit('SET_LOADING_BIENS', false);
      return false;
    }
  },
  async createBien({ commit }, payload) {
    commit('SET_ERROR_BIENS', []);
    try {
      const response = await axios.post(domain + `/api/biens/create`, payload, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('ADD_NEW_BIEN', response.data.data);
      return { success: true };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        return { success: false, error: data };
      } else {
        if (error && error.response && error.response.data) {
          return { success: false, error: error.response.data.error };
        } else {
          return { success: false, error: 'Une erreur est survenue' };
        }
      }
    }
  },
  async updateBlockedBien({ commit }, payload) {
    commit('SET_LOADING_BIENS', true);
    try {
      let params = {
        id: payload.id,
        blocked: payload.blocked
      };
      const response = await axios.post(
        domain + `/api/biens/blocked_bien`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_BLOCKED_BIEN', {
        item: payload.item,
        response: response.data.data
      });
      commit('SET_LOADING_BIENS', false);

      return true;
    } catch (error) {
      commit('SET_LOADING_BIENS', false);
      return false;
    }
  },
  async updateBien({ commit }, payload) {
    commit('SET_ERROR_BIENS', []);
    try {
      const response = await axios.post(
        domain + `/api/biens/update`,
        payload.bienToUpdate,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_BIEN', {
        item: payload.oldBienToUpdate,
        response: response.data.data
      });
      return { success: true };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        return { success: false, error: data };
      } else {
        if (error && error.response && error.response.data) {
          return { success: false, error: error.response.data.error };
        } else {
          return { success: false, error: 'Une erreur est survenue' };
        }
      }
    }
  },
  resetState({ commit }) {
    commit('SET_ERROR_BIENS', []);
  },
  async exportListBien({ commit }, payload) {
    try {
      const response = await axios.get(domain + `/api/biens/export_bien`, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      this.state.showNotifExportFile = true;
      return { success: true, msg: response.data };
    } catch (error) {
      return { success: false };
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
