import RowThNew from './RowThNew';
export default class GroupeRowThNew {
  constructor(data) {
    this.name = data.name;
    this.check = false;
    this.showDetails = false;
    this.projects = data.projects.map(item => RowThNew.create(item));
  }
  static create(data) {
    return new GroupeRowThNew(data);
  }
}
