import axios from 'axios';
import domain from '@/environment';
import vueDynamiqueAVModal from '../../models/vueDynamiqueAV';
const prefixRapportView = 'rapportView';
let cancelRequestVueDynamic = null;
const state = {
  loadingVueDynamiqueAV: false,
  errorVueDynamiqueAV: null,
  vueDynamiqueAV: []
};
const getters = {
  getVueDynamiqueAV: state => state.vueDynamiqueAV,
  getLoadingVueDynamiqueAV: state => state.loadingVueDynamiqueAV,
  getErrorVueDynamiqueAV: state => state.errorVueDynamiqueAV
};
const mutations = {
  SET_LOADING_VUE_DYN_AV(state, payload) {
    state.loadingVueDynamiqueAV = payload;
  },
  SET_ERROR_VUE_DYN_AV(state, payload) {
    state.errorVueDynamiqueAV = payload;
  },
  NEW_VUE_DYN_AV(state, payload) {
    state.vueDynamiqueAV.push(vueDynamiqueAVModal.create(payload));
  },
  SET_VUE_DYN_AV(state, payload) {
    state.vueDynamiqueAV = payload.map(item =>
      vueDynamiqueAVModal.create(item)
    );
  },
  SET_ORDER_WEEK(state, payload) {
    state.vueDynamiqueAV.update(payload.response);
  },
  DELETE_VUE_DYN_AV(state, payload) {
    state.vueDynamiqueAV = state.vueDynamiqueAV.filter(
      item => item.id != payload
    );
  },
  BLOCKE_VUE_DYN_AV(state, payload) {
    payload.item.update(payload.response);
  },
  UPDATE_NAME_VUE_DYN_AV(state, payload) {
    const index = state.vueDynamiqueAV.findIndex(
      item => item.id == payload.item.id
    );
    state.vueDynamiqueAV[index].update(payload.response);
  }
};
const actions = {
  async AddVueDynamiqueAV({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/${prefixRapportView}/create_category`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('NEW_VUE_DYN_AV', response.data.data);
      return {
        success: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          success: false,
          error: error.response.data.error
        };
      } else {
        return {
          success: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  },
  async getAllVueDynamiqueAV({ commit }, payload) {
    commit('SET_ERROR_VUE_DYN_AV', null);

    commit('SET_LOADING_VUE_DYN_AV', true);
    let params = {};
    if (payload?.blocked != null) {
      params.blocked = payload.blocked;
    }
    try {
      if (cancelRequestVueDynamic) {
        cancelRequestVueDynamic.cancel('cancel-request');
      }
      cancelRequestVueDynamic = axios.CancelToken.source();
      const response = await axios.get(
        domain + `/api/${prefixRapportView}/all_categorys`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          cancelToken: cancelRequestVueDynamic.token
        }
      );
      commit('SET_VUE_DYN_AV', response.data.data);
      commit('SET_LOADING_VUE_DYN_AV', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_VUE_DYN_AV', error.response.data);
      } else {
        commit('SET_ERROR_VUE_DYN_AV', 'Une erreur est survenue');
      }
      commit('SET_LOADING_VUE_DYN_AV', false);

      return false;
    }
  },
  async deleteVueDynamiqueAV({ commit }, payload) {
    commit('SET_LOADING_VUE_DYN_AV', true);

    try {
      await axios.post(
        domain + `/api/${prefixRapportView}/delete_category/${payload}`,
        { permission: payload.name },
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_VUE_DYN_AV', payload);
      commit('SET_LOADING_VUE_DYN_AV');
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_VUE_DYN_AV', error.response.data.error);
      } else {
        commit('SET_ERROR_VUE_DYN_AV', ['Une erreur est survenue']);
      }
      commit('SET_LOADING_VUE_DYN_AV');
      return false;
    }
  },
  async updateNameVueDynamiqueAV({ commit }, payload) {
    commit('SET_LOADING_VUE_DYN_AV', true);
    const formData = new FormData();
    formData.append('name', payload.name);
    formData.append('id', payload.id);
    for (let i = 0; i < payload.columns.length; i++) {
      formData.append('columns[' + i + '][key]', payload.columns[i].key);
      formData.append('columns[' + i + '][label]', payload.columns[i].label);
      formData.append(
        'columns[' + i + '][disabled]',
        payload.columns[i].disabled
      );
      if (payload.columns[i].thClass) {
        formData.append(
          'columns[' + i + '][thClass]',
          payload.columns[i].thClass
        );
      } else {
        formData.append('columns[' + i + '][thClass]', '');
      }
      if (payload.columns[i].tdClass) {
        formData.append(
          'columns[' + i + '][tdClass]',
          payload.columns[i].tdClass
        );
      } else {
        formData.append('columns[' + i + '][tdClass]', '');
      }
    }
    try {
      const response = await axios.post(
        domain + `/api/${prefixRapportView}/update_category`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      commit('UPDATE_NAME_VUE_DYN_AV', {
        item: payload,
        response: response.data.data
      });
      commit('SET_LOADING_VUE_DYN_AV');
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_VUE_DYN_AV', error.response.data.error);
      } else {
        commit('SET_ERROR_VUE_DYN_AV', ['Une erreur est survenue']);
      }
      commit('SET_LOADING_VUE_DYN_AV');
      return false;
    }
  },
  async blockVueDynamiqueAV({ commit }, payload) {
    commit('SET_LOADING_VUE_DYN_AV', true);

    try {
      const response = await axios.post(
        domain + `/api/${prefixRapportView}/block_category`,
        { id: payload.id, blocked: !payload.blocked },
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('BLOCKE_VUE_DYN_AV', {
        item: payload,
        response: response.data.data
      });
      commit('SET_LOADING_VUE_DYN_AV');
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_VUE_DYN_AV', error.response.data.error);
      } else {
        commit('SET_ERROR_VUE_DYN_AV', ['Une erreur est survenue']);
      }
      commit('SET_LOADING_VUE_DYN_AV');
      return false;
    }
  },
  async uploadExcelFileToFundDifference({ commit }, payload) {
    try {
      const response = await axios.post(domain + 'url', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      return true;
    } catch (error) {
      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
