import FactureMensuel from './FactureMensuel';
export default class Mensuel {
  constructor(data) {
    this.check = false;
    this.name = data.name;
    this.total_net_ht = data.total_ht;
    this.total_tva = data.total_tva;
    this.total_ttc = data.total_ttc;
    this.factures = data.factures.map(facture =>
      FactureMensuel.create(facture)
    );
    this.showDetails = false;
  }
  static create(data) {
    return new Mensuel(data);
  }
}
