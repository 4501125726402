<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import pusherExport from './pusher/export_excel_pusher';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'App',

  data: () => ({
    //
  }),
  methods: {
    ...mapActions([
      'setCurrentTpe',
      'clearCacheForUser',
      'fetchBadgeNumberReglement',
      'updateVisibiliteMenuFacture'
    ])
  },
  watch: {
    '$route.name'() {
      let visibilite =
        this.$route.name == 'facture-libre' ||
        this.$route.name == 'simulationFactureFrais' ||
        this.$route.name == 'visionGlobaleFrais' ||
        this.$route.name == 'facture-sci' ||
        this.$route.name == 'gestion-facture-mensuel' ||
        this.$route.name == 'masterFiliale-2' ||
        this.$route.name == 'appel-paiement' ||
        this.$route.name == 'pixel-facture'
          ? true
          : false;
      this.updateVisibiliteMenuFacture(visibilite);
    }
  },
  computed: {
    ...mapGetters(['getUserData', 'getCurrentType'])
  },
  async mounted() {
    if (process.env.VUE_APP_ENV === 'develop') {
      document.title = `[dev] ${document.title}`;
    }
    if (this.getUserData && this.getUserData.refresh == false) {
      await this.clearCacheForUser(this.getUserData.id);
      location.reload(true);
    }

    if (this.getUserData) {
      pusherExport.getNotificationExportFile();
    }
    this.fetchBadgeNumberReglement();
  }
};
</script>
