import axios from 'axios';
import domain from '@/environment';
const state = {
  loaderVendeurMailObligee: false,
  loaderAcheteurMailObligee: false,
  MailDataVendeurObligee: null,
  MailDataAcheteurObligee: null,
  errorAcheteurMailObligee: null,
  errorVendeurMailObligee: null,
  arrayMsgSendMailObligee: []
};
const getters = {
  loaderAcheteurMailObligee: state => state.loaderAcheteurMailObligee,
  loaderVendeurMailObligee: state => state.loaderVendeurMailObligee,
  MailDataVendeurObligee: state => state.MailDataVendeurObligee,
  MailDataAcheteurObligee: state => state.MailDataAcheteurObligee,
  errorAcheteurMailObligee: state => state.errorAcheteurMailObligee,
  errorVendeurMailObligee: state => state.errorVendeurMailObligee,
  arrayMsgSendMailObligee: state => state.arrayMsgSendMailObligee
};
const mutations = {
  SET_ACHETEUR_MAIL_DATA_OBLIGEE(state, payload) {
    if (payload != null) {
      var pay = { ...payload };
      pay.extra_mails_list = [...payload.extra_mails];
      pay.vendeur_client = true;
      state.MailDataAcheteurObligee = pay;
    } else {
      state.MailDataAcheteurObligee = payload;
    }
  },
  SET_VENDEUR_MAIL_DATA_OBLIGEE(state, payload) {
    if (payload != null) {
      var pay = { ...payload };
      pay.extra_mails_list = [...payload.extra_mails];
      pay.vendeur_client = true;
      state.MailDataVendeurObligee = pay;
    } else {
      state.MailDataVendeurObligee = payload;
    }
  },
  SET_ACHETEUR_MAIL_DATA_OBLIGEE_LOADER(state, payload) {
    state.loaderAcheteurMailObligee = payload;
  },
  SET_VENDEUR_MAIL_DATA_OBLIGEE_LOADER(state, payload) {
    state.loaderVendeurMailObligee = payload;
  },
  SET_ACHETEUR_MAIL_DATA_OBLIGEE_ERROR(state, payload) {
    state.errorAcheteurMailObligee = payload;
  },
  SET_VENDEUR_MAIL_DATA_OBLIGEE_ERROR(state, payload) {
    state.errorVendeurMailObligee = payload;
  },
  SET_MSG_SEND_MAIL_OBLIGEEQ(state, payload) {
    state.arrayMsgSendMailObligee.push(payload);
  }
};
const actions = {
  async getMailDataVAObligee({ commit }, payload) {
    if (payload.vendeur_client == 'vendeur') {
      commit('SET_VENDEUR_MAIL_DATA_OBLIGEE_ERROR', null);
    } else {
      commit('SET_ACHETEUR_MAIL_DATA_OBLIGEE_ERROR', null);
    }
    if (payload.vendeur_client == 'vendeur') {
      commit('SET_VENDEUR_MAIL_DATA_OBLIGEE_LOADER', true);
    } else {
      commit('SET_ACHETEUR_MAIL_DATA_OBLIGEE_LOADER', true);
    }
    if (payload.vendeur_client == 'vendeur') {
      commit('SET_VENDEUR_MAIL_DATA_OBLIGEE', null);
    } else {
      commit('SET_ACHETEUR_MAIL_DATA_OBLIGEE', null);
    }
    try {
      const response = await axios.post(
        domain + '/api/obligee/mail_html',
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      if (payload.vendeur_client == 'vendeur') {
        commit('SET_VENDEUR_MAIL_DATA_OBLIGEE', response.data);
      } else {
        commit('SET_ACHETEUR_MAIL_DATA_OBLIGEE', response.data);
      }
      if (payload.vendeur_client == 'vendeur') {
        commit('SET_VENDEUR_MAIL_DATA_OBLIGEE_LOADER', false);
      } else {
        commit('SET_ACHETEUR_MAIL_DATA_OBLIGEE_LOADER', false);
      }
    } catch (error) {
      if (payload.vendeur_client == 'vendeur') {
        if (error?.response?.data?.error) {
          commit(
            'SET_VENDEUR_MAIL_DATA_OBLIGEE_ERROR',
            error.response.data.error
          );
        } else {
          commit(
            'SET_VENDEUR_MAIL_DATA_OBLIGEE_ERROR',
            'Une erreur est servenue'
          );
        }
      } else {
        if (error?.response?.data?.error) {
          commit(
            'SET_ACHETEUR_MAIL_DATA_OBLIGEE_ERROR',
            error.response.data.error
          );
        } else {
          commit(
            'SET_ACHETEUR_MAIL_DATA_OBLIGEE_ERROR',
            'Une erreur est servenue'
          );
        }
      }
      if (payload.vendeur_client == 'vendeur') {
        commit('SET_VENDEUR_MAIL_DATA_OBLIGEE_LOADER', false);
      } else {
        commit('SET_ACHETEUR_MAIL_DATA_OBLIGEE_LOADER', false);
      }
    }
  },
  async sendMailDataVAObligee({ commit }, payload) {
    const params = {
      lot: payload.lot,
      html: payload.html,
      extra_mails: payload.extra_mails_list.map(item => item.email),
      mail: payload.mail,
      bills_ids: payload.bills_ids,
      title: payload.title
    };
    try {
      const response = await axios.post(
        domain + '/api/obligee/mail_send',
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_MSG_SEND_MAIL_OBLIGEEQ', {
        msg: response.data.message,
        vu: false,
        type: 'success',
        lot: payload.lot,
        title: 'Mail envoyé avec succès'
      });
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_MSG_SEND_MAIL_OBLIGEEQ', {
          msg: error.response.data.error,
          vu: false,
          type: 'error',
          lot: payload.lot,
          title: 'Email erronée'
        });
      } else {
        commit('SET_MSG_SEND_MAIL_OBLIGEEQ', {
          msg: 'Une erreur est servenue',
          vu: false,
          type: 'error',
          lot: payload.lot
        });
      }
    }
  },
  async addExtraMail({ commit }, payload) {
    try {
      let response = await axios.post(
        `${domain}/api/filiale/add_extra_mail`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      return { success: true, result: response.data.data };
    } catch (error) {
      if (error?.response?.data) {
        return { success: false, result: error.response.data.errors.email };
      } else {
        return { success: false, result: 'Une erreur est survenue' };
      }
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
