import axios from 'axios';
import domain from '@/environment';

let requestStatsAcheteur = null;
let requestStatsVendeur = null;
const state = {
  loadingVendeur: false,
  loadingAcheteur: false,
  dataAcheteur: [],
  dataVendeur: [],
};

const getters = {
  getStatsLoadingVendeur: state => state.loadingVendeur,
  getStatsLoadingAcheteur: state => state.loadingAcheteur,
  getStatsAcheteur: state => state.dataAcheteur,
  getStatsVendeur: state => state.dataVendeur,
}
const mutations = {
  SET_LOADING_STATS_VENDEUR(state , payload)
  {
    state.loadingVendeur = payload 
  },
  SET_LOADING_STATS_ACHETEUR(state, payload) {
    state.loadingAcheteur = payload;
  },
  SET_STATS_DATA_ACHETEUR(state, payload) {
    state.dataAcheteur = payload.map(item => ({ ...item, open: false }));
  },
  SET_STATS_DATA_VENDEUR(state, payload) {
    state.dataVendeur = payload.map(item => ({ ...item, open: false }));
  },
  INIT_STATS_DATA_ACHETEUR(state, payload) {
    state.dataAcheteur = payload;
  },
  INIT_STATS_DATA_VENDEUR(state, payload) {
    state.dataVendeur = payload;
  }
};
const actions = {
  initDataAcheteur({ commit }, payload) {
    commit('INIT_STATS_DATA_ACHETEUR', []);
  },
  initDataVendeur({ commit }, payload) {
    commit('INIT_STATS_DATA_VENDEUR', []);
  },
  async fetchStatsAcheteur({ commit }, payload) {
    commit('SET_LOADING_STATS_ACHETEUR', true);
    try {
      if (requestStatsAcheteur) {
        requestStatsAcheteur.cancel('cancel-request');
      }
      requestStatsAcheteur = axios.CancelToken.source();
      const response = await axios.post(
        domain + `/api/statistique/statistics_filiale_acheteur`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          cancelToken: requestStatsAcheteur.token
        }
      );

      commit('SET_STATS_DATA_ACHETEUR', response.data);
      commit('SET_LOADING_STATS_ACHETEUR', false);
    } catch (error) {
      if (error.message !== 'cancel-request') {
        commit('SET_LOADING_STATS_ACHETEUR', false);
      }
      return false;
    }
  },
  async fetchStatsVendeur({ commit }, payload) {
    commit('SET_LOADING_STATS_VENDEUR', true);
    try {
      if (requestStatsVendeur) {
        requestStatsVendeur.cancel('cancel-request');
      }
      requestStatsVendeur = axios.CancelToken.source();
      const response = await axios.post(
        domain + `/api/statistique/statistics_filiale_vendeur`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          cancelToken: requestStatsVendeur.token
        }
      );

      commit('SET_STATS_DATA_VENDEUR', response.data);
      commit('SET_LOADING_STATS_VENDEUR', false);
    } catch (error) {
      if (error.message !== 'cancel-request') {
        commit('SET_LOADING_STATS_VENDEUR', false);
      }
      return false;
    }
  },
};
export default {
  state,
  getters,
  actions,
  mutations
};
