import axios from 'axios';
import domain from '@/environment';
import calenderTh from '../../models/calenderTh';
let cancelRequestCalenderTh = null;
const state = {
  loadingCalenderTh: false,
  errorCalenderTh: null,
  calendersTh: [],
  loadingCalenderWeekTh: false
};
const getters = {
  getCalendersTh: state => state.calendersTh,
  getLoadingCalenderTh: state => state.loadingCalenderTh,
  getErrorCalenderTh: state => state.errorCalenderTh,
  getLoadingCalenderWeekTh: state => state.loadingCalenderWeekTh
};
const mutations = {
  SET_LOADING_CALENDER_TH(state, payload) {
    state.loadingCalenderTh = payload;
  },
  SET_ERROR_CALANDER_TH(state, payload) {
    state.errorCalenderTh = payload;
  },
  NEW_CALENDER_TH(state, payload) {
    state.calendersTh = payload.map(item => calenderTh.create(item));
  },
  SET_CALENDER_TH(state, payload) {
    state.calendersTh = payload.map(item => calenderTh.create(item));
  },
  ADD_DEPOT_TO_MONTH(state, payload) {
    payload.weekToUpdate.update(payload.response);
  },
  DELETE_DEPOT_IN_MONTH_TH(state, payload) {
    for (let index = 0; index < payload.month.weeks.length; index++) {
      if (payload.month.weeks[index].id == payload.week.id) {
        let indexFiliale = payload.month.weeks[index].filiales.findIndex(
          item => item.id == payload.filiale.id
        );
        let indexDepot = payload.month.weeks[index].filiales[
          indexFiliale
        ].depots.findIndex(item => item.id == payload.depot.id);
        index = payload.month.weeks[index].filiales[indexFiliale].depots.splice(
          indexDepot,
          1
        );
        return;
      }
    }
  },
  SET_LOADING_CALENDER_WEEK_TH(state, payload) {
    state.loadingCalenderWeekTh = payload;
  },
  SET_ORDER_WEEK_TH(state, payload) {
    let indexWeek = payload.payload.month.weeks.findIndex(
      item => item.id == payload.payload.week.id
    );
    let indexNewMonth = state.calendersTh.findIndex(
      item => item.id == payload.payload.month_to
    );
    payload.payload.month.weeks.splice(indexWeek, 1);
    if (payload.payload.type == 'backward') {
      state.calendersTh[indexNewMonth].weeks.push(payload.response);
    } else {
      state.calendersTh[indexNewMonth].weeks.unshift(payload.response);
    }
  }
};
const actions = {
  async AddCalendrierTh({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + `/api/add_year_calendar_th`,

        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: { year: payload }
        }
      );
      commit('NEW_CALENDER_TH', response.data.data);
      return {
        success: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          success: false,
          error: error.response.data.error
        };
      } else {
        return {
          success: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  },
  async getCallenderTh({ commit }, payload) {
    commit('SET_ERROR_CALANDER_TH', null);
    commit('SET_LOADING_CALENDER_TH', true);
    try {
      if (cancelRequestCalenderTh) {
        cancelRequestCalenderTh.cancel('cancel-request');
      }
      cancelRequestCalenderTh = axios.CancelToken.source();
      const response = await axios.get(domain + `/api/all_months_calendar_th`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: { year: payload },
        cancelToken: cancelRequestCalenderTh.token
      });
      commit('SET_CALENDER_TH', response.data.data);
      commit('SET_LOADING_CALENDER_TH', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_CALANDER_TH', error.response.data);
      } else {
        commit('SET_ERROR_CALANDER_TH', 'Une erreur est survenue');
      }
      commit('SET_LOADING_CALENDER_TH', false);

      return false;
    }
  },
  async addDepotToMonth({ commit }, payload) {
    commit('SET_ERROR_CALANDER_TH', null);

    const params = {
      year: payload.month.year,
      id: payload.Week.month_id,
      depot: payload.depot,
      filiale: payload.filiale,
      week_id: payload.Week.id
    };
    commit('SET_LOADING_CALENDER_WEEK_TH', true);
    try {
      const response = await axios.get(domain + `/api/add_depot_calendar_th`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('ADD_DEPOT_TO_MONTH', {
        response: response.data.data,
        weekToUpdate: payload.month
      });
      commit('SET_LOADING_CALENDER_WEEK_TH', false);
      return {
        succes: true
      };
    } catch (error) {
      commit('SET_LOADING_CALENDER_WEEK_TH', false);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  },
  async deleteDepotInMonth({ commit }, payload) {
    commit('SET_ERROR_CALANDER_TH', null);

    commit('SET_LOADING_CALENDER_WEEK_TH', true);
    try {
      const response = await axios.get(
        domain + `/api/delete_depot_calendar_th/${payload.depot.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_DEPOT_IN_MONTH_TH', {
        depot: payload.depot,
        month: payload.month,
        week: payload.week,
        filiale: payload.filiale
      });
      commit('SET_LOADING_CALENDER_WEEK_TH', false);
      return {
        succes: true
      };
    } catch (error) {
      commit('SET_LOADING_CALENDER_WEEK_TH', false);

      if (error && error.response && error.response.data) {
        return {
          succes: false,
          error: error.response.data
        };
      } else {
        return {
          succes: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  },
  async updateNameDepotTh({ commit }, payload) {
    commit('SET_ERROR_CALANDER_TH', null);
    commit('SET_LOADING_CALENDER_WEEK_TH', true);
    try {
      let params = {
        year: payload.month.year,
        id: payload.depot.id,
        depot: payload.name,
        filiale: payload.filiale
      };
      const response = await axios.post(
        domain + `/api/edit_depot_calendar_th`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_DEPOT_TO_MONTH', {
        response: response.data.data,
        weekToUpdate: payload.month
      });
      commit('SET_LOADING_CALENDER_WEEK_TH', false);
      return {
        succes: true
      };
    } catch (error) {
      commit('SET_LOADING_CALENDER_WEEK_TH', false);

      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  },
  async updateFilialeDepotTh({ commit }, payload) {
    commit('SET_ERROR_CALANDER_TH', null);

    commit('SET_LOADING_CALENDER_WEEK_TH', true);
    try {
      let params = {
        year: payload.month.year,
        id: payload.depot.id,
        depot: payload.depot_name,
        filiale: payload.filiale
      };
      const response = await axios.post(
        domain + `/api/edit_depot_calendar_th`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_DEPOT_TO_MONTH', {
        response: response.data.data,
        weekToUpdate: payload.month
      });
      commit('SET_LOADING_CALENDER_WEEK_TH', false);
      return {
        succes: true
      };
    } catch (error) {
      commit('SET_LOADING_CALENDER_WEEK_TH', false);

      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: 'Une erreur est survenue'
        };
      }

      return false;
    }
  },
  async DragWeekTh({ commit }, payload) {
    commit('SET_ERROR_CALANDER_TH', null);

    const params = {
      id: payload.week.id,
      month_id: payload.month_to
    };
    commit('SET_LOADING_CALENDER_TH', true);
    try {
      const response = await axios.post(
        domain + `/api/change_week_month`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_ORDER_WEEK_TH', {
        response: response.data.data,
        payload: payload
      });
      commit('SET_LOADING_CALENDER_TH', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('SET_ERROR_CALANDER_TH', error.response.data.errors);
      } else {
        commit('SET_ERROR_CALANDER_TH', 'Une erreur est survenue');
      }
      commit('SET_LOADING_CALENDER_TH', false);
      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
