export default class parametreSpecifique {
  constructor(data) {
    this.id = data.id;
    this.config_id = data.config_id;
    this.filiale_id = data.filiale;
    this.coef_prime_installateur = data.coef_prime_installateur;
    this.tva_coef_prime_installateur = data.tva_coef_prime_installateur;
    this.coef_commercial_prime = data.coef_commercial_prime;
    this.tva_coef_commercial_prime = data.tva_coef_commercial_prime;
    this.coef_commercial_group = data.coef_commercial_group;
    this.tva_coef_commercial_group = data.tva_coef_commercial_group;
    this.tva_coef_surprime_group = data.tva_coef_surprime_group;
    this.coef_surprime_group = data.coef_surprime_group;
    this.coef_commission_gestion_dossier_administrative =
      data.coef_commission_gestion_dossier_administrative;
    this.tva_coef_commission_gestion_dossier_administrative =
      data.tva_coef_commission_gestion_dossier_administrative;
    this.listTva = []
  }
  update(data) {
    this.id = data.id;
    this.config_id = data.config_id;
    this.filiale_id = data.filiale;
    this.coef_prime_installateur = data.coef_prime_installateur;
    this.tva_coef_prime_installateur = data.tva_coef_prime_installateur;
    this.coef_commercial_prime = data.coef_commercial_prime;
    this.tva_coef_commercial_prime = data.tva_coef_commercial_prime;
    this.coef_commercial_group = data.coef_commercial_group;
    this.tva_coef_commercial_group = data.tva_coef_commercial_group;
    this.tva_coef_surprime_group = data.tva_coef_surprime_group;
    this.coef_surprime_group = data.coef_surprime_group;
    this.coef_commission_gestion_dossier_administrative =
      data.coef_commission_gestion_dossier_administrative;
    this.tva_coef_commission_gestion_dossier_administrative =
      data.tva_coef_commission_gestion_dossier_administrative;
  }
  static create(data) {
    return new parametreSpecifique(data);
  }
}
