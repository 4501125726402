import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import store from '../store/index';
window.pusher = require('pusher-js');
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'a531dcd950d5a7bcdd70',
  encrypt: true,
  cluster: 'eu'
});
const checkanalyse = function() {
  window.Echo.channel('channel-job-finished').listen('JobFinished', function(
    e
  ) {
    var pourcentage = parseInt(e.pourcentage);
    store.commit('SET_PROGRESS', pourcentage);
    store.commit('SET_STATUS_FOR_REFRECH_DATA');
  });
};
const getnotificationForAdmin = function() {
  window.Echo.channel('notification').listen('.admin', function(e) {
    var newNotification = e.notif;
    store.commit('NEW_NOTIFICATION', newNotification);
  });
};
const checkvalidation = function() {
  window.Echo.channel('Salarys').listen('.admin', function(e) {
    store.commit('SET_PROGRESS_VALIDATION', e);
  });
};
const destroyed = function() {
  window.Echo.leave('notification');
  window.Echo.leave('channel-job-finished');
  window.Echo.leave('Salarys');
};
export default {
  checkanalyse,
  getnotificationForAdmin,
  destroyed,
  checkvalidation
};
