import domain from '@/environment';
import axios from 'axios';
import Mensuel from '../../models/Mensuel';
let requestMensuel = null;
const state = {
  factureMensuels: [],
  errorMensuels: [],
  acheteurMensuels: [],
  vendeurMensuels: [],
  loadingFactureMensuel: false,
  loadingMensuel: false,
  rowsMensuel: 0,
  templateFactureMensuel: null,
  sousObjets: []
};
const getters = {
  factureMensuels: state => state.factureMensuels,
  errorMensuels: state => state.errorMensuels,
  loadingMensuelFacture: state => state.loadingFactureMensuel,
  loadingMensuel: state => state.loadingMensuel,
  TotalRowsMensuel: state => state.rowsMensuel,
  templateFactureMensuel: state => state.templateFactureMensuel,
  acheteurMensuels: state => state.acheteurMensuels,
  vendeurMensuels: state => state.vendeurMensuels,
  sousObjetsMensuels: state => state.sousObjets
};
const mutations = {
  UPDATE_FACTURE_AVOIR_MENSUEL(state, payload) {
    payload.row.updateAvoir(payload.response);
  },
  SET_SOUS_OBJETS_FAMILLE(state, payload) {
    state.sousObjets = payload;
  },
  SET_ERROR_FACTURE_MENSUEL(state, payload) {
    state.errorMensuels = payload;
  },
  SET_ALL_FACTURES_MENSUEL(state, payload) {
    state.factureMensuels = payload.map(item => Mensuel.create(item));
  },
  SET_LOADING_FACTURES_MENSUEL(state, payload) {
    state.loadingFactureMensuel = payload;
  },
  SET_LOADING_FACTURES_MENSUEL_CONF(state, payload) {
    state.loadingMensuel = payload;
  },
  SET_TOTAL_ROWS_MENSUEL(state, payload) {
    state.rowsMensuel = payload;
  },
  UPDATE_STATUT_CLIENT_MENSUEL(state, payload) {
    payload.item.update(payload.response);
  },
  UPDATE_STATUT_FACTURE_MENSUEL(state, payload) {
    payload.item.update(payload.response);
  },
  UPDATE_FACTURE_MENSUEL(state, payload) {
    payload.item.update(payload.response);
  },
  SET_TEMPLATE_FACTURE_MENSUEL(state, payload) {
    state.templateFactureMensuel = payload;
  },
  UPDATE_MONTANT_HT_FACTURE_MENSUEL(state, payload) {
    payload.item.update(payload.response);
  },
  DELETE_TRANSFERT_FACTURE_IDS(state, payload) {
    for (let i = 0; i < payload.length; i++) {
      let parentindex = state.factureMensuels.findIndex(
        item => item.name == payload[i].indexOfFather
      );
      let childIndex = state.factureMensuels[parentindex].factures.findIndex(
        item => item.id == payload[i].indexOfChild
      );
      state.factureMensuels[parentindex].factures.splice(childIndex, 1);
      if (state.factureMensuels[parentindex].factures.length === 0) {
        state.factureMensuels.splice(parentindex, 1);
      }
    }
  },
  UPDATE_VALIDATE_MULTIPLE_FACTURE_MENSUEL(commit, payload) {
    payload.data.map(item => {
      let index = payload.response.findIndex(i => i.id == item.id);
      if (index != -1) {
        {
          item.update(payload.response[index]);
        }
      }
    });
  },
  SET_ACHETEURS_FACTURES_MENSUELS(state, payload) {
    state.acheteurMensuels = payload;
  },
  SET_VENDEURS_FACTURES_MENSUELS(state, payload) {
    state.vendeurMensuels = payload;
  },
  UPDATE_AVOIR_RESULT_FACTURE_MENSUEL_ID(state, payload) {
    payload.data.map(item => {
      let index = payload.response.findIndex(i => i.id == item.id);
      if (index != -1) {
        {
          item.update(payload.response[index]);
        }
      }
    });
  },
  UPDATE_STATUT_FACTURE_MENSUEL_ID(state, payload) {
    payload.data.map(item => {
      let index = payload.response.findIndex(i => i.id == item.id);
      if (index != -1) {
        {
          item.update(payload.response[index]);
        }
      }
    });
  },
  UPDATE_STATUT_CLIENT_MENSUEL_SCI(state, payload) {
    payload.data.map(item => {
      let index = payload.response.findIndex(i => i.id == item.id);
      if (index != -1) {
        {
          item.update(payload.response[index]);
        }
      }
    });
  }
};
const actions = {
  async getFacturesMensuels({ commit }, payload) {
    commit('SET_LOADING_FACTURES_MENSUEL', true);
    const params = {
      per_page: payload.per_page,
      page: payload.page,
      famille: payload.famille,
      column: payload.column,
      order: payload.order,
      grouped: true
    };
    if (payload && payload.nums) {
      params.nums = payload.nums;
    }
    for (let i = 0; i < payload?.etat?.length; i++) {
      params['etat[' + i + ']'] = payload.etat[i].value;
    }
    for (let i = 0; i < payload?.vendeur_id?.length; i++) {
      params['vendeur_id[' + i + ']'] = payload?.vendeur_id[i]?.value;
    }
    for (let i = 0; i < payload?.acheteur_id?.length; i++) {
      params['acheteur_id[' + i + ']'] = payload?.acheteur_id[i]?.value;
    }
    for (let i = 0; i < payload?.statut_client?.length; i++) {
      params['statut_client[' + i + ']'] = payload?.statut_client[i]?.value;
    }
    if (payload.search != null) {
      params.num = payload.search;
    }
    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    if (payload.mois != null) {
      params.mois = payload.mois;
    }
    try {
      if (requestMensuel) {
        commit('SET_ERROR_FACTURE_MENSUEL', null);
        requestMensuel.cancel('cancel-request');
      }
      requestMensuel = axios.CancelToken.source();
      const response = await axios.get(domain + `/api/libre/factures`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: requestMensuel.token
      });
      commit('SET_ALL_FACTURES_MENSUEL', response.data.data);
      commit('SET_TOTAL_ROWS_MENSUEL', response.data.count);
      commit('SET_LOADING_FACTURES_MENSUEL', false);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_FACTURE_MENSUEL', error.response.data.error);
        commit('SET_LOADING_FACTURES_MENSUEL', false);
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_LOADING_FACTURES_MENSUEL', false);
          commit('SET_ERROR_FACTURE_MENSUEL', ['Une erreur est survenue']);
        }
      }
    }
  },
  async createAvoirMensuel({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/frais/create_avoir_partiel`,
        payload.bodyFormData,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_FACTURE_AVOIR_MENSUEL', {
        response: response.data.data,
        row: payload.item
      });
      return {
        success: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          success: false,
          error: error.response.data.error
        };
      } else {
        return {
          success: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },

  async updateStatutClientFactureMensuel({ commit }, payload) {
    commit('SET_LOADING_FACTURES_MENSUEL', true);
    const params = {
      id: payload.row.id,
      statut_client: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/libre/statut_client`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_STATUT_CLIENT_MENSUEL', {
        response: response.data.data,
        item: payload.row
      });
      commit('SET_LOADING_FACTURES_MENSUEL', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_FACTURE_MENSUEL', error.response.data.error);
      } else {
        commit('SET_ERROR_FACTURE_MENSUEL', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FACTURES_MENSUEL', false);

      return false;
    }
  },
  async addFactureMensuel({ commit }, payload) {
    commit('SET_LOADING_FACTURES_MENSUEL', true);

    try {
      const response = await axios.post(
        domain + `/api/mensuel/add_facture`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LOADING_FACTURES_MENSUEL', false);

      return {
        succes: true,
        message: response.data.data
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_LOADING_FACTURES_MENSUEL', false);

        return {
          success: false,
          error: error.response.data.error
        };
      } else {
        commit('SET_LOADING_FACTURES_MENSUEL', false);

        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async updateStatutFactureMensuel({ commit }, payload) {
    commit('SET_LOADING_FACTURES_MENSUEL', true);
    const params = {
      id: payload.row.id,
      etat: payload.value
    };
    try {
      const response = await axios.put(domain + `/api/libre/etat`, params, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('UPDATE_STATUT_FACTURE_MENSUEL', {
        response: response.data.data,
        item: payload.row
      });

      commit('SET_LOADING_FACTURES_MENSUEL', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_FACTURE_MENSUEL', error.response.data.error);
      } else {
        commit('SET_ERROR_FACTURE_MENSUEL', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FACTURES_MENSUEL', false);

      return false;
    }
  },
  async getTemplateFactureMensuel({ commit }, payload) {
    commit('SET_ERROR_FACTURE_MENSUEL', null);
    commit('SET_LOADING_FACTURES_MENSUEL', true);
    try {
      const response = await axios.get(
        domain +
          `/api/filiale_template_generator/get_uploaded_template_for_libre`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      commit('SET_TEMPLATE_FACTURE_MENSUEL', response.data.data);

      commit('SET_LOADING_FACTURES_MENSUEL', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_FACTURE_MENSUEL', error.response.data.error);
      } else {
        commit('SET_ERROR_FACTURE_MENSUEL', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FACTURES_MENSUEL', false);

      return false;
    }
  },
  async validateFactureTemplate({ commit }, payload) {
    const params = {
      description: payload.description,
      iban: payload.iban,
      rib: payload.rib,
      swift: payload.swift,
      bank: payload.bank,
      date: payload.date,
      date_reglement: payload.date_reglement,
      ids: [payload.id_facture],
      id_template: payload.id_template
    };
    if (payload.payment_condition && payload.payment_condition.id != null) {
      params.payment_condition = payload.payment_condition.id;
    }
    try {
      const response = await axios.post(
        domain + `/api/libre/confirme-master`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_FACTURE_MENSUEL', {
        response: response.data.data[0],
        item: payload.item
      });
      return {
        success: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        return {
          succes: false,
          error: error.response.data.errors
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async exportPdfFactureMensuel({ commit }, payload) {
    commit('SET_ERROR_FACTURE_MENSUEL', null);
    commit('SET_LOADING_FACTURES_MENSUEL', true);
    try {
      const response = await axios.get(
        domain + `/api/filiale_template_generator/download_libre`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );

      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_FACTURES_MENSUEL', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_FACTURE_MENSUEL', ['Une erreur est servenue']);
      commit('SET_LOADING_FACTURES_MENSUEL', false);
      return false;
    }
  },
  async avoirGlobaleFactureMensuel({ commit }, payload) {
    try {
      const response = await axios.post(
        `${domain}/api/libre/avoir_globale_for_all`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { success: true, response: response.data.data };
    } catch (error) {
      if (error?.response?.data?.error) {
        return { success: false, response: error?.response?.data?.error };
      } else {
        return { success: false, response: error?.response?.data?.error };
      }
    }
  },
  async updateMontantHtFactureMensuel({ commit }, payload) {
    commit('SET_LOADING_FACTURES_MENSUEL', true);
    const params = {
      id: payload.row.id,
      montant_ht: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/mensuel/update_montant_ht`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_MONTANT_HT_FACTURE_MENSUEL', {
        response: response.data.data,
        item: payload.row
      });

      commit('SET_LOADING_FACTURES_MENSUEL', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_FACTURE_MENSUEL', error.response.data.error);
      } else {
        commit('SET_ERROR_FACTURE_MENSUEL', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FACTURES_MENSUEL', false);

      return false;
    }
  },
  async transfertFactureMensuelToLibre({ commit }, payload) {
    commit('SET_LOADING_FACTURES_MENSUEL', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/transfer-to-libre`,
        payload.ids,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('DELETE_TRANSFERT_FACTURE_IDS', payload.indexsToRemove);

      commit('SET_LOADING_FACTURES_MENSUEL', false);
      return { succes: true };
    } catch (error) {
      commit('SET_LOADING_FACTURES_MENSUEL', false);
      return false;
    }
  },
  async validateMultiFactureMensuel({ commit }, payload) {
    payload.map(item => item.id_facture);
    var ids = new FormData();
    for (let i = 0; i < payload.length; i++) {
      ids.append('ids[' + [i] + ']', payload[i].id);
    }
    try {
      const response = await axios.post(
        domain + `/api/libre/confirme-multi-master`,
        ids,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_VALIDATE_MULTIPLE_FACTURE_MENSUEL', {
        response: response.data.data,
        data: payload
      });
      return {
        succes: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async getVendeurAcheteurMensuels({ commit }) {
    commit('SET_ERROR_FACTURE_MENSUEL', null);
    const payload = {
      famille: 'mensuel'
    };
    try {
      const response = await axios.get(
        domain + `/api/libre/get_vendeurs_acheteurs_by_factures`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      commit('SET_ACHETEURS_FACTURES_MENSUELS', response.data.acheteurs);
      commit('SET_VENDEURS_FACTURES_MENSUELS', response.data.vendeurs);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_FACTURE_MENSUEL', error.response.data.error);
      } else {
        commit('SET_ERROR_FACTURE_MENSUEL', ['Une erreur est servenue']);
      }
      return false;
    }
  },
  async getSousObjets({ commit }, payload) {
    commit('SET_ERROR_FACTURE_MENSUEL', null);
    const params = {
      famille: payload.famille
    };
    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_debut = payload.date_fin;
    }
    try {
      const response = await axios.get(domain + `/api/libre/get_sous_objets`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_SOUS_OBJETS_FAMILLE', response.data.data);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_FACTURE_MENSUEL', error.response.data.error);
      } else {
        commit('SET_ERROR_FACTURE_MENSUEL', ['Une erreur est servenue']);
      }
      return false;
    }
  },
  async updateStatutGlobaleMensuelSci({ commit }, payload) {
    let bodyFormData = new FormData();
    bodyFormData.append('etat', payload.etat);
    for (let i = 0; i < payload.ids_facture.length; i++) {
      bodyFormData.append('ids[' + i + ']', payload.ids_facture[i].id);
    }
    commit('SET_ERROR_FACTURE_MENSUEL', null);
    commit('SET_LOADING_FACTURES_MENSUEL', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/multiple_etat`,
        bodyFormData,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_STATUT_FACTURE_MENSUEL_ID', {
        data: payload.ids_facture,
        response: response.data.data
      });
      commit('SET_LOADING_FACTURES_MENSUEL', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_ERROR_FACTURE_MENSUEL', error.response.data.errors);
      } else {
        commit('SET_ERROR_FACTURE_MENSUEL', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FACTURES_MENSUEL', false);

      return false;
    }
  },
  //update statue installateur
  async updateStatutClientGlobaleMensuelSCI({ commit }, payload) {
    let bodyFormData = new FormData();
    bodyFormData.append('statut_client', payload.etat);
    for (let i = 0; i < payload.ids_facture.length; i++) {
      bodyFormData.append('ids[' + i + ']', payload.ids_facture[i].id);
    }
    commit('SET_ERROR_FACTURE_MENSUEL', null);
    commit('SET_LOADING_FACTURES_MENSUEL', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/multiple_statut_client`,
        bodyFormData,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('UPDATE_STATUT_CLIENT_MENSUEL_SCI', {
        data: payload.ids_facture,
        response: response.data.data
      });
      commit('SET_LOADING_FACTURES_MENSUEL', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_ERROR_FACTURE_MENSUEL', error.response.data.errors);
      } else {
        commit('SET_ERROR_FACTURE_MENSUEL', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FACTURES_MENSUEL', false);

      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
