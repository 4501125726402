export default {
  state: {
    errorTemplate: null,
    templateUpload: null,
    loadingTemplateUpload: false
  },
  getters: {
    getErrorUploadTemplateGlobal: state => state.errorTemplate,
    getTemplateGlobal: state => state.templateUpload,
    getLoadingTemplateGlobal: state => state.loadingTemplateUpload
  },
  mutations: {
    SET_ERROR_GET_TEMPLATE(state, payload) {
      state.errorTemplate = payload;
    },
    SET_TEMPLATE_FACTURE(state, payload) {
      state.templateUpload = payload;
    },
    SET_LOADING_GET_TEMPLATE(state, payload) {
      state.loadingTemplateUpload = payload;
    }
  },
  actions: {
    async getTemplateFacture({ commit }, payload) {
      commit('SET_ERROR_GET_TEMPLATE', null);
      commit('SET_LOADING_GET_TEMPLATE', true);
      try {
        const response = await axios.get(
          domain +
            `/api/filiale_template_generator/get_uploaded_template_for_libre`,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            },
            params: payload
          }
        );
        commit('SET_TEMPLATE_FACTURE', response.data.data);

        commit('SET_LOADING_GET_TEMPLATE', false);
        return true;
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          commit('SET_ERROR_GET_TEMPLATE', error.response.data.error);
        } else {
          commit('SET_ERROR_GET_TEMPLATE', ['Une erreur est servenue']);
        }
        commit('SET_LOADING_GET_TEMPLATE', false);

        return false;
      }
    },
    async getTemplateAndFiledsDataToUpdateChange(
      { commit },
      payload
    ) {
      try {
        const response = await axios.get(
          domain +
            `/api/filiale_template_generator/get_uploaded_template_for_libre`,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            },
            params: payload
          }
        );

        return { success: true, data: response.data.data };
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          return { success: false, error: error.response.data.error };
        } else {
          return { success: false, error: 'Une erreur est servenue' };
        }
      }
    },
    async getTemplateAndFiledsDataFactureToUpdate({ commit }, payload) {
        commit('SET_ERROR_GET_TEMPLATE', null);
        commit('SET_LOADING_GET_TEMPLATE', true);
        try {
          const response = await axios.get(
            domain +
              `/api/filiale_template_generator/get_uploaded_template_for_libre`,
            {
              headers: {
                Authorization: `Bearer ${this.state.User.access_token}`
              },
              params: payload
            }
          );
          commit('SET_TEMPLATE_MF_DATA_FRAIS_DYNC', response.data.data);
    
          commit('SET_LOADING_GET_TEMPLATE', false);
    
          return true;
        } catch (error) {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            commit(
              'SET_ERROR_GET_TEMPLATE',
              error.response.data.error
            );
          } else {
            commit('SET_ERROR_GET_TEMPLATE', [
              'Une erreur est servenue'
            ]);
          }
          commit('SET_LOADING_GET_TEMPLATE', false);
    
          return false;
        }
      },
    setDataUploadedTemplate({ commit }) {
        commit('SET_TEMPLATE_FACTURE' , null);
      }
  }
};
