import axios from 'axios';
import domain from '@/environment';

export default {
  state: {
    pseudos: [],
    loaderPseudoFiliale: false,
    errorPseudo: []
  },
  getters: {
    getListPseudosFiliale: state => state.pseudos,
    getLoaderPseudoFiliale: state => state.loaderPseudoFiliale,
    getErrorPseudoFiliale: state => state.errorPseudo
  },
  mutations: {
    SET_PSEUDO_LOADER(state, payload) {
      state.loaderPseudoFiliale = payload;
    },
    SET_PSEUDO_FIILIALE(state, payload) {
      state.pseudos = payload;
    },
    SET_ERROR_PSEUDO_FILIALE(state, payload) {
      state.errorPseudo = payload;
    },
    ADD_NEW_PSEUDO(state, payload) {
      state.pseudos.push(payload);
    },
    UPDATE_PSEUDO_FILIALE(state, payload) {
      state.pseudos.map(pseudo => {
        if (pseudo.id == payload.id) {
          return Object.assign({}, pseudo, payload);
        }
        return payload;
      });
    },
    DELETE_PSEUDO_FILIALE(state, payload) {
      let index = state.pseudos.findIndex(pseudo => pseudo.id == payload);
      state.pseudos.splice(index, 1);
    }
  },
  actions: {
    async getAllPseudoFiliale({ commit }, payload) {
      commit('SET_PSEUDO_LOADER', true);
      try {
        const response = await axios.get(
          domain + `/api/filiale/pseudos/${payload}`,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('SET_PSEUDO_FIILIALE', response.data);
        commit('SET_PSEUDO_LOADER', false);
        return true;
      } catch (error) {
        if (error?.response?.data?.errors) {
          let data = [];
          for (var item in error.response.data.errors) {
            if (error.response.data.errors[item].length)
              data.push(...error.response.data.errors[item]);
          }
          commit('SET_ERROR_PSEUDO_FILIALE', data);
        } else if (error?.response?.data?.error) {
          commit('SET_ERROR_PSEUDO_FILIALE', error.response.data.error);
        } else {
          commit('SET_ERROR_PSEUDO_FILIALE', 'Une erreur est survenue');
        }
        commit('SET_PSEUDO_LOADER', false);
        return false;
      }
    },
    async addNewPseudoFiliale({ commit }, payload) {
      commit('SET_ERROR_PSEUDO_FILIALE', null);
      try {
        const response = await axios.post(
          domain + `/api/filiale/addPseudo`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('ADD_NEW_PSEUDO', response.data);
        commit('SET_ERROR_PSEUDO_FILIALE', null);
        return true;
      } catch (error) {
        if (error?.response?.data?.errors) {
          let data = [];
          for (var item in error.response.data.errors) {
            if (error.response.data.errors[item].length)
              data.push(...error.response.data.errors[item]);
          }
          commit('SET_ERROR_PSEUDO_FILIALE', data);
        } else if (error?.response?.data?.error) {
          commit('SET_ERROR_PSEUDO_FILIALE', error.response.data.error);
        } else {
          commit('SET_ERROR_PSEUDO_FILIALE', 'Une erreur est survenue');
        }
        return false;
      }
    },
    async updatePseudoFiliale({ commit }, payload) {
      commit('SET_ERROR_PSEUDO_FILIALE', null);
      try {
        const response = await axios.put(
          domain + `/api/filiale/updatePseudo/${payload.id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('UPDATE_PSEUDO_FILIALE', response.data);
        commit('SET_ERROR_PSEUDO_FILIALE', null);
        return true;
      } catch (error) {
        if (error?.response?.data?.errors) {
          let data = [];
          for (var item in error.response.data.errors) {
            if (error.response.data.errors[item].length)
              data.push(...error.response.data.errors[item]);
          }
          commit('SET_ERROR_PSEUDO_FILIALE', data);
        } else if (error?.response?.data?.error) {
          commit('SET_ERROR_PSEUDO_FILIALE', error.response.data.error);
        } else {
          commit('SET_ERROR_PSEUDO_FILIALE', 'Une erreur est survenue');
        }
        return false;
      }
    },
    async deletePseudoFiliale({ commit }, payload) {
      try {
        await axios.delete(domain + `/api/filiale/deletePseudos/${payload}`, {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        });
        commit('DELETE_PSEUDO_FILIALE', payload);
        return true;
      } catch (error) {
        if (error && error.response && error.response.data) {
          commit('SET_ERROR_PSEUDO_FILIALE', error.response.data.data);
        } else {
          commit('SET_ERROR_PSEUDO_FILIALE', 'Une erreur est survenue');
        }
        return false;
      }
    },
    resetPseudoFiliale({ commit }, payload) {
      commit('SET_ERROR_PSEUDO_FILIALE', payload);
    }
  }
};
