export default class Bien {
  constructor(data) {
    this.id = data.id;
    this.nom = data.nom;
    this.adresse = data.adresse;
    this.description = data.description;
    this.blocked = data.blocked;
    this.type = data.type;
  }

  update(data) {
    this.id = data.id;
    this.nom = data.nom;
    this.adresse = data.adresse;
    this.blocked = data.blocked;
    this.description = data.description;
    this.type = data.type;
  }

  static create(data) {
    return new Bien(data);
  }
}
