import axios from 'axios';
import domain from '@/environment';
import configurationVariable from '../../models/configurationVariable';
import gestionConfigurationFamilly from '@/models/gestionConfigurationFamilly';
import moment from 'moment';
import GestionConfiguration from '../../models/gestionConfiguration';
let cancelErrors = null;
let cancelRequestFormuleConfig = null;
let cancelRequestConfig = null;
const state = {
  filialsSupport: [],
  proFilials: [],
  NumeriqCulomn: [],
  AllCulomns: [],
  ConfigurationErrors: null,
  isLoadingConfigFraisTh: false,
  AllConfigurations: [],
  totalRowConf: 0,
  errorForSpecific: null,
  loadingListSupports: false,
  listFamilleConfigFrias: [],
  errorFamilleConfig: null,
  loadingFamilleConfig: false
};
const getters = {
  getFilialsSupport: state => state.filialsSupport,
  getProFilials: state => state.proFilials,
  getProFilialsParticulier: state =>
    state.proFilials
      .filter(i => i.type == 'particulier')
      .map(i => {
        return { ...i, full_name: i.name, tva: i.tva };
      }),
  getNumeriqCulomn: state => state.NumeriqCulomn,
  getColumns: state => state.AllCulomns,
  getIsLoadingConfigFraisTh: state => state.isLoadingConfigFraisTh,
  getConfigurationError: state => state.ConfigurationErrors,
  getTotalRowConf: state => state.totalRowConf,
  getAllConfigurations: state => state.AllConfigurations,
  getErrorForSpecific: state => state.errorForSpecific,
  getloadingListSupports: state => state.loadingListSupports,
  getListFamilleConfigFrias: state => state.listFamilleConfigFrias,
  getErrorFamilleConfig: state => state.errorFamilleConfig,
  getLoadingFamilleConfig: state => state.loadingFamilleConfig
};
const mutations = {
  ADD_NEW_CONF(state, payload) {
    let existe = false;
    state.AllConfigurations.map(configuration => {
      if (configuration.name == payload.filiale_name) {
        existe = true;
        configuration.configs.unshift(GestionConfiguration.create(payload));
      }
    });
    if (existe == false) {
      let configuration = {
        name: payload.filiale_name,
        configs: [payload]
      };
      state.AllConfigurations.unshift(
        configurationVariable.create(configuration)
      );
    }
  },
  SET_ALL_CONFIG(state, payload) {
    state.AllConfigurations = payload.map(item =>
      configurationVariable.create(item)
    );
  },
  UPDATE_CONFIG(state, payload) {
    payload.originConfig.update(payload.response);
  },
  UPDATE_CONFIG_BLOCK(state, payload) {
    payload.blocked = !payload.blocked;
  },
  SET_FILIALES_SUPPORTS(state, payload) {
    state.filialsSupport = payload;
  },
  SET_PRO_FILIALS_SUPPORTS(state, payload) {
    state.proFilials = payload;
  },
  SET_NUM_CULOMN(state, payload) {
    state.NumeriqCulomn = payload;
  },
  SET_CULOMNS(state, payload) {
    state.AllCulomns = payload;
  },
  SET_IS_LOADING(state, payload) {
    state.isLoadingConfigFraisTh = payload;
  },
  SET_CONFIGURATION_ERROR(state, payload) {
    state.ConfigurationErrors = payload;
  },
  SET_TOTAL_ROWS_CONF(state, payload) {
    state.totalRowConf = payload;
  },
  INIT_ERRORS(state, payload) {
    state.ConfigurationErrors = payload;
    state.errorForSpecific = payload;
  },
  SET_ERROR_SPECIFIC(state, payload) {
    state.errorForSpecific = payload;
  },
  SET_LOADING_LIST_SUPPORTS(state, payload) {
    state.loadingListSupports = payload;
  },
  // FAMILLE
  SET_LIST_FAMILLE_CONFIG_FRAIS(state, payload) {
    if (payload.blocked != undefined) {
      payload.response = payload.response.filter(item => item.blocked == 0);
    }
    state.listFamilleConfigFrias = payload.response.map(item =>
      gestionConfigurationFamilly.create(item)
    );
  },
  ADD_NEW_CONF_FAMILLE(state, payload) {
    state.listFamilleConfigFrias.push(
      gestionConfigurationFamilly.create(payload)
    );
  },
  UPDATE_CONFIG_FAMILLE_BLOCK(state, payload) {
    payload.blocked = !payload.blocked;
  },
  UPDATE_CONFIG_FAMILLE(state, payload) {
    payload.familly.update(payload.response);
  },
  SET_CONFIGURATION_FAMILLE_ERROR(state, payload) {
    state.errorFamilleConfig = payload;
  },
  SET_IS_LOADING_FAMMILY_CONFIG(state, payload) {
    state.loadingFamilleConfig = payload;
  }
};
const actions = {
  async fetchAllconfigurations({ commit }, payload) {
    const params = {};
    if (payload?.page) {
      params.page = payload.page;
    }
    if (payload?.per_page) {
      params.per_page = payload.per_page;
    }
    if (payload?.date_debut) {
      params.date_debut = payload.date_debut;
    }
    if (payload?.search) {
      params.search = payload.search;
    }
    if (payload?.date_fin) {
      params.date_fin = payload.date_fin;
    }
    if (payload?.active) {
      params.active = payload.active;
    }
    if (payload?.active_date) {
      params.active_date = payload.active_date;
    }
    if (payload?.famille) {
      params.famille = payload.famille;
    }

    if (payload?.vendeurs) {
      params.vendeurs = payload.vendeurs.map(item => item.id);
    }
    if (payload?.commercials && payload?.famille == 'commercial') {
      params.commercials = payload.commercials.map(item => item.id);
    }
    commit('SET_CONFIGURATION_ERROR', null);
    commit('SET_IS_LOADING', true);
    try {
      if (cancelRequestConfig) {
        cancelRequestConfig.cancel('cancel-request');
      }
      cancelRequestConfig = axios.CancelToken.source();
      const response = await axios.get(
        `${domain}/api/config_societe_support/get_all`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          cancelToken: cancelRequestConfig.token,
          params: params
        }
      );
      commit('SET_ALL_CONFIG', response.data.data);
      commit('SET_TOTAL_ROWS_CONF', response.data.count);
      commit('SET_IS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_CONFIGURATION_ERROR', error?.response?.data?.errors);
      } else {
        if (error.message !== 'cancel-request') {
          commit('SET_CONFIGURATION_ERROR', 'Une erreur est survenue');
        }
      }
      commit('SET_IS_LOADING', false);
    }
  },
  async getAllFilialSupport({ commit }, payload) {
    commit('SET_LOADING_LIST_SUPPORTS', true);
    commit('SET_CONFIGURATION_ERROR', null);
    try {
      const response = await axios.get(
        `${domain}/api/config_societe_support/get_supports`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_FILIALES_SUPPORTS', response.data.data);
      commit('SET_LOADING_LIST_SUPPORTS', false);

      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_CONFIGURATION_ERROR', error?.response?.data?.errors);
      } else {
        commit('SET_CONFIGURATION_ERROR', 'Une erreur est survenue');
      }
      commit('SET_LOADING_LIST_SUPPORTS', false);
    }
  },
  async getAllProFilial({ commit }, payload) {
    commit('SET_CONFIGURATION_ERROR', null);
    try {
      const response = await axios.get(
        `${domain}/api/config_societe_support/get_filiales`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_PRO_FILIALS_SUPPORTS', response.data.data);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_CONFIGURATION_ERROR', error?.response?.data?.errors);
      } else {
        commit('SET_CONFIGURATION_ERROR', 'Une erreur est survenue');
      }
    }
  },
  async getAllNumColums({ commit }, payload) {
    commit('SET_CONFIGURATION_ERROR', null);
    try {
      const response = await axios.get(
        `${domain}/api/config_societe_support/get_numeric_columns`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_NUM_CULOMN', response.data);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_CONFIGURATION_ERROR', error?.response?.data?.errors);
      } else {
        commit('SET_CONFIGURATION_ERROR', 'Une erreur est survenue');
      }
    }
  },
  async getAllColumns({ commit }, payload) {
    commit('SET_CONFIGURATION_ERROR', null);
    try {
      const response = await axios.get(
        `${domain}/api/config_societe_support/get_columns`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_CULOMNS', response.data);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_CONFIGURATION_ERROR', error?.response?.data?.errors);
      } else {
        commit('SET_CONFIGURATION_ERROR', 'Une erreur est survenue');
      }
    }
  },
  async addNewConfiguration({ commit }, payload) {
    let params;
    let validKeys = [];
    if (payload.acheteur_type == "bureau d'etude") {
      validKeys.push('acheteur_id');
    }
    if (payload.coef_type == 'montant fix') {
      payload.formules = [];
    }
    const filteredEntries = Object.entries(payload).filter(
      ([key]) => !validKeys.includes(key)
    );
    params = Object.fromEntries(filteredEntries);
    params.commercials = params.commercials.map(i => i.id);
    params.filiale_id = params.filiale_id;
    params.famillies = params.famillies?.map(item => item.id);
    params.date_debut =
      params.date_debut != null
        ? moment(params.date_debut, 'DD-MM-YYYY').format('YYYY-MM-DD')
        : params.date_debut;
    params.date_fin =
      params.date_fin != null
        ? moment(params.date_fin, 'DD-MM-YYYY').format('YYYY-MM-DD')
        : params.date_fin;
    commit('SET_CONFIGURATION_ERROR', null);
    commit('SET_IS_LOADING', true);
    try {
      const response = await axios.post(
        `${domain}/api/config_societe_support/create`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_NEW_CONF', response.data.data);
      commit('SET_IS_LOADING', false);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_IS_LOADING', false);
        commit('SET_CONFIGURATION_ERROR', data);
        return { success: false };
      } else {
        commit('SET_IS_LOADING', false);
        commit('SET_CONFIGURATION_ERROR', 'Une erreur est survenue');
        return { success: false };
      }
    }
  },
  async checkPeriod({ commit }, payload) {
    commit('SET_CONFIGURATION_ERROR', null);
    try {
      if (cancelErrors) {
        cancelErrors.cancel('cancel-request');
      }
      cancelErrors = axios.CancelToken.source();
      const response = await axios.post(
        `${domain}/api/config_societe_support/errors`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          cancelToken: cancelErrors.token
        }
      );
      return { success: true };
    } catch (error) {
      if (error?.response?.data) {
        commit('SET_ERROR_SPECIFIC', error?.response?.data);
        return { success: false };
      }
    }
  },

  async updateConfiguration({ commit }, payload) {
    let params;
    let validKeys = [];
    if (payload.configurationToUpdate.acheteur_type == "bureau d'etude") {
      validKeys.push('acheteur_id');
    }
    if (payload.configurationToUpdate.coef_type == 'montant fix') {
      payload.configurationToUpdate.formules = [];
    }
    const filteredEntries = Object.entries(
      payload.configurationToUpdate
    ).filter(([key]) => !validKeys.includes(key));
    params = Object.fromEntries(filteredEntries);
    params.commercials = params.commercials.map(i => i.id);
    params.famillies = params.famillies?.map(item => item.id);
    commit('SET_CONFIGURATION_ERROR', null);
    commit('SET_IS_LOADING', true);
    try {
      const response = await axios.post(
        `${domain}/api/config_societe_support/update`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_CONFIG', {
        response: response.data.data,
        originConfig: payload.configuration
      });
      commit('SET_IS_LOADING', false);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_IS_LOADING', false);
        commit('SET_CONFIGURATION_ERROR', data);
        return { success: false };
      } else {
        commit('SET_IS_LOADING', false);
        commit('SET_CONFIGURATION_ERROR', 'Une erreur est survenue');
        return { success: false };
      }
    }
  },
  async BlockUnblockConfigFraisDync({ commit }, payload) {
    commit('SET_CONFIGURATION_ERROR', null);
    commit('SET_IS_LOADING', true);
    try {
      await axios.post(
        `${domain}/api/config_societe_support/Block_unblock/${payload.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_CONFIG_BLOCK', payload);
      commit('SET_IS_LOADING', false);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_IS_LOADING', false);
        commit('SET_CONFIGURATION_ERROR', data);
        return { success: false };
      } else {
        commit('SET_IS_LOADING', false);
        commit('SET_CONFIGURATION_ERROR', 'Une erreur est survenue');
        return { success: false };
      }
    }
  },
  // familly
  async fetchAllFammilleConfig({ commit }, payload) {
    const params = {};
    commit('SET_CONFIGURATION_FAMILLE_ERROR', null);
    commit('SET_IS_LOADING_FAMMILY_CONFIG', true);
    try {
      const response = await axios.get(
        `${domain}/api/config_societe_support/familly_support/index`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );

      commit('SET_LIST_FAMILLE_CONFIG_FRAIS', {
        response: response.data.data,
        blocked: payload.blocked
      });
      commit('SET_IS_LOADING_FAMMILY_CONFIG', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit(
          'SET_CONFIGURATION_FAMILLE_ERROR',
          error?.response?.data?.errors
        );
      } else {
        commit('SET_CONFIGURATION_FAMILLE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_IS_LOADING_FAMMILY_CONFIG', false);
    }
  },
  async addNewFamilleConfigFrais({ commit }, payload) {
    let params = { familly: payload.name };
    commit('SET_CONFIGURATION_FAMILLE_ERROR', null);
    try {
      const response = await axios.post(
        `${domain}/api/config_societe_support/familly_support/create`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_NEW_CONF_FAMILLE', response.data.data);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_CONFIGURATION_FAMILLE_ERROR', error.response.data.error);
        return false;
      } else {
        commit('SET_CONFIGURATION_FAMILLE_ERROR', 'Une erreur est survenue');
        return false;
      }
    }
  },
  async updateConfigFamilleFraisDync({ commit }, payload) {
    commit('SET_CONFIGURATION_FAMILLE_ERROR', null);
    try {
      const response = await axios.post(
        `${domain}/api/config_societe_support/familly_support/update`,
        payload.famillyToUpdate,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_CONFIG_FAMILLE', {
        familly: payload.familly,
        response: response.data.data
      });
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_CONFIGURATION_FAMILLE_ERROR', error.response.data.error);
        return false;
      } else {
        commit('SET_CONFIGURATION_FAMILLE_ERROR', 'Une erreur est survenue');
        return false;
      }
    }
  },
  async BlockUnblockConfigFamilleFraisDync({ commit }, payload) {
    commit('SET_CONFIGURATION_FAMILLE_ERROR', null);
    commit('SET_IS_LOADING_FAMMILY_CONFIG', true);
    try {
      await axios.post(
        `${domain}/api/config_societe_support/familly_support/block_unblock/${payload.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_CONFIG_FAMILLE_BLOCK', payload);
      commit('SET_IS_LOADING_FAMMILY_CONFIG', false);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_IS_LOADING_FAMMILY_CONFIG', false);
        commit('SET_CONFIGURATION_FAMILLE_ERROR', data);
        return { success: false };
      } else {
        commit('SET_IS_LOADING_FAMMILY_CONFIG', false);
        commit('SET_CONFIGURATION_FAMILLE_ERROR', 'Une erreur est survenue');
        return { success: false };
      }
    }
  },

  initErrorsFamillyConfig({ commit }) {
    commit('SET_CONFIGURATION_FAMILLE_ERROR', null);
  },
  initErrors({ commit }) {
    commit('INIT_ERRORS', null);
  },
  async checkFormuleConfigFraisDynamicMethod({ commit }, payload) {
    commit('SET_CONFIGURATION_ERROR', null);
    try {
      if (cancelRequestFormuleConfig) {
        cancelRequestFormuleConfig.cancel('cancel-request');
      }
      cancelRequestFormuleConfig = axios.CancelToken.source();
      const response = await axios.get(
        `${domain}/api/config_societe_support/check_formule`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          cancelToken: cancelRequestFormuleConfig.token,
          params: payload
        }
      );
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        return { success: false, error: error.response.data.error };
      }
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
