export default class Reglements {
  constructor(data) {
    this.check = false;
    this.id = data.id;
    this.type = data.type;
    this.type_id = data.type_id;
    this.montant = data.montant;
    this.montant_initial = data.montant;
    this.condition = data.condition;
    this.condition_id = data.condition_id;
    this.balance = data.balance;
    this.balance_initial = data.balance;
    this.currency = data.currency;
    this.payment_date = data.payment_date;
    this.reference = data.reference;
    this.canConfirm = data.canConfirm;
    this.confirmation_fournisseur = data.confirmation_fournisseur;
    this.payment_note = data.payment_note;
    this.comptabilise = data.comptabilise;
    this.client = data.client;
    this.client_id = data.client_id;
    this.societe = data.societe;
    this.solde_echeance = 0;
    this.societe_id = data.societe_id;
    this.factures_regles = data.factures_regles.map(item => {
      return { ...item, check: false };
    });
    this.echeances = data.echeances.map(item => {
      return { ...item, check: false };
    });

    this.totalEcheance = 0;
    this.files = data.files;
    this.solde = 0;
    this.solde_init = 0;
    this.type_partenaire = data.type_partenaire;
  }
  updateFilesReglements(data) {
    this.files = data;
  }
  updateEcheance(data) {
    this.echeances = data;
  }
  update(data) {
    this.check = false;
    this.id = data.id;
    this.type = data.type;
    this.montant = data.montant;
    this.montant_initial = data.montant;
    this.balance = data.balance;
    this.balance_initial = data.balance;
    this.type_id = data.type_id;
    this.condition_id = data.condition_id;
    this.currency = data.currency;
    this.canConfirm = data.canConfirm;
    this.payment_date = data.payment_date;
    this.solde_echeance = 0;
    this.reference = data.reference;
    this.payment_note = data.payment_note;
    this.comptabilise = data.comptabilise;
    this.client = data.client;
    this.client_id = data.client_id;
    this.societe = data.societe;
    this.confirmation_fournisseur = data.confirmation_fournisseur;
    this.societe_id = data.societe_id;
    this.factures_regles = data.factures_regles?.map(item => {
      return { ...item, check: false };
    });
    this.echeances = data.echeances.map(item => {
      return { ...item, check: false };
    });
    this.files = data.files;
    this.solde = 0;
    this.solde_init = 0;

    this.type_partenaire = data.type_partenaire;
  }
  removeFiles(data) {
    this.files = this.files.filter(i => i.id != data)
  }
  static create(data) {
    return new Reglements(data);
  }
}
