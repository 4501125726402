import axios from 'axios';
import domain from '@/environment';
import store from '../index';
import RowThNew from '@/models/RowThNew';
import GroupeRowThNew from '@/models/GroupeRowThNew';
let requestanaluseValidationData = null;
let requestanaluseValidationDataStatistic = null;
let requestanaluseValidationDataFilter = null;

const state = {
  dataRapportTh: [],
  loaderRapportTh: false,
  errorRapportTh: null,
  loaderFilterRapportTh: false,
  errorFilterRapportTh: null,
  filterRapportThDateDepotDossier: [],
  filterRapportThDepot: [],
  filterRapportThGruopes: [],
  filterRapportThOrganisme: [],
  filterRapportThStatut: [],
  loaderStatisticRapportTh: false,
  errorStatisticRapportTh: null,
  dataStatisticRapportTh: {
    ADP: 0,
    facture_edite: 0,
    paye: 0,
    total_montant_final: 0
  },
  dataStatisticChartRapportTh: {
    // total_montant_final: 0,
    total_montant_final_ADP: 0,
    total_montant_final_facture_edite: 0,
    total_montant_final_paye: 0
  },
  loaderUpdateColumnRapportTh: false,
  totalRapportTh: null,
  loaderExportDataRapportTh: false,
  fieldsAnalyseConfig: [
    {
      key: 'numero_dossier',
      editable: false
    },
    { key: 'version', editable: false },
    { key: 'depot', editable: false },
    { key: 'statut', type: 'text', editableType: 'input', editable: false },
    {
      key: 'organisme',
      type: '',
      editableType: 'select',
      editable: true,
      // optionsSelect: store.getters.SettingOrganismeTh.map(item => ({
      //   full_name: item.name
      // })),
      api: true,
      getters: 'SettingOrganismeTh',
      formate: true
    },
    {
      key: 'installeur',
      type: '',
      editableType: 'select',
      editable: true,
      api: true,
      getters: 'getSettingFilialesTh',
      formate: true

      // optionsSelect: store.getters.getSettingFilialesTh.map(item => ({
      //   full_name: item.name
      // }))
    },
    { key: 'nom1', editable: false },
    { key: 'prenom1', editable: false },
    { key: 'adresse', type: 'text', editableType: 'input', editable: true },
    { key: 'code_postal', type: 'text', editableType: 'input', editable: true },
    { key: 'departement', type: 'text', editableType: 'input', editable: true },
    { key: 'etat_de_statut', editable: false },
    { key: 'etape', type: 'text', editableType: 'input', editable: true },
    { key: 'categorie', type: 'text', editableType: 'input', editable: true },
    {
      key: 'super_regie',
      type: '',
      editableType: 'select',
      editable: true,
      optionsSelect: [
        { value: 'EXTERNE', full_name: 'EXTERNE' },
        { value: 'INTERNE', full_name: 'INTERNE' }
      ]
    },
    {
      key: 'regie',
      type: '',
      editableType: 'select',
      editable: true,
      getters: 'getAllRegie',
      formate: true,
      api: true
      // optionsSelect: store.getters.getAllRegie.map(item => ({
      //   full_name: item.name
      // }))
    },
    {
      key: 'agent_commercial_terrain',
      type: '',
      editableType: 'selectInput',
      editable: true,
      getters: 'getProFilialsParticulier',
      api: true
      // optionsSelect: store.getters.getListPrevisiteurTh.map(item => ({
      //   full_name: item.full_name
      // }))
    },
    {
      key: 'agent_commercial',
      type: '',
      editableType: 'select',
      editable: true,
      getters: 'getListCommercialSedentaireTh',
      api: true
      // optionsSelect: store.getters.getListCommercialSedentaireTh
    },
    {
      key: 'agent_administratif',
      editable: true,
      type: 'text',
      editableType: 'input'
    },
    {
      key: 'agent_technique',
      type: 'text',
      editableType: 'input',
      editable: true
    },
    {
      key: 'responsable_qualite',
      type: 'text',
      editableType: 'input',
      editable: true
    },
    {
      key: 'pose_date',
      type: 'datetime',
      editableType: 'datetime',
      editable: true
    },
    {
      key: 'pose_semaine',
      type: 'text',
      editableType: 'input',
      editable: true
    },
    {
      key: 'poseur',
      type: '',
      editableType: 'select',
      editable: true,
      getters: 'getListPoseurTh',
      api: true
      // optionsSelect: store.getters.getListPoseurTh.map(item => ({
      //   full_name: item.full_name
      // }))
    },
    { key: 'poseur_pac', editable: true, type: 'text', editableType: 'input' },
    {
      key: 'coposeurs',
      type: '',
      editableType: 'select',
      editable: true,
      getters: 'getListPoseurTh',
      api: true
      // optionsSelect: store.getters.getListPoseurTh.map(item => ({
      //   full_name: item.full_name
      // }))
    },
    {
      key: 'conducteur_de_travaux',
      editable: true,
      type: 'text',
      editableType: 'input'
    },
    {
      key: 'montant_final',
      type: 'text',
      editableType: 'input',
      editable: true
    },
    {
      key: 'montant_total_ht',
      type: 'text',
      editableType: 'input',
      editable: true
    },
    {
      key: 'montant_net_ht',
      type: 'text',
      editableType: 'input',
      editable: true
    },
    {
      key: 'montant_TVA',
      editable: true,
      type: 'text',
      editableType: 'input'
    },
    {
      key: 'montant_prime_cee',
      type: 'text',
      editableType: 'input',
      editable: true
    },
    {
      key: 'montant_mpr',
      type: 'text',
      editableType: 'input',
      editable: true
    },
    {
      key: 'montant_total_ttc',
      type: 'text',
      editableType: 'input',
      editable: true
    },
    ////
    {
      key: 'reste_a_charge',
      editable: true,
      type: 'text',
      editableType: 'input'
    },
    { key: 'ballon', type: 'text', editableType: 'input', editable: true },
    { key: 'RO', type: 'text', editableType: 'input', editable: true },
    { key: 'RR', type: 'text', editableType: 'input', editable: true },
    {
      key: 'surface_101_souflee',
      type: 'text',
      editableType: 'input',
      editable: true
    },
    {
      key: 'surface_101_deroule',
      type: 'text',
      editableType: 'input',
      editable: true
    },
    {
      key: 'surface_103_plafond',
      type: 'text',
      editableType: 'input',
      editable: true
    },

    {
      key: 'surface_101_rampants',
      type: 'text',
      editableType: 'input',
      editable: true
    },
    {
      key: 'surface_102_murs_iti',
      type: 'text',
      editableType: 'input',
      editable: true
    },
    {
      key: 'surface_102_murs_ite',
      type: 'text',
      editableType: 'input',
      editable: true
    },
    { key: 'VMC', type: 'text', editableType: 'input', editable: true },
    { key: 'kwh_cumac', type: 'text', editableType: 'input', editable: true },
    {
      key: 'precarite',
      type: '',
      editableType: 'select',
      editable: true,
      optionsSelect: [
        { full_name: 'Classique' },
        { full_name: 'Grande' },
        { full_name: 'Simple' }
      ]
    },
    {
      key: 'couleur',
      type: '',
      editableType: 'select',
      editable: true,
      optionsSelect: [
        { full_name: 'Bleu' },
        { full_name: 'Jaune' },
        { full_name: 'Violet' },
        { full_name: 'Rose' }
      ]
    },
    {
      key: 'type_de_chauffage',
      type: '',
      editableType: 'select',
      editable: true,
      optionsSelect: [{ full_name: 'Electrique' }, { full_name: 'Combustible' }]
    },
    {
      key: 'personnes_au_foyer',
      type: 'text',
      editableType: 'input',
      editable: true
    },
    {
      key: 'etat_du_devis',
      editable: true,
      type: 'text',
      editableType: 'input'
    },
    {
      key: 'devis_signe_par',
      editable: true,
      type: 'text',
      editableType: 'input'
    },
    {
      key: 'numero_de_facture',
      type: 'text',
      editableType: 'input',
      editable: true
    },
    {
      key: 'numero_de_lot',
      type: 'text',
      editableType: 'input',
      editable: false
    },
    {
      key: 'numero_de_depot',
      type: 'text',
      editableType: 'input',
      editable: true
    },
    {
      key: 'date_dengagement',
      type: 'date',
      editableType: 'datetime',
      editable: true
    },
    {
      key: 'date_facture',
      type: 'date',
      editableType: 'datetime',
      editable: true
    },
    {
      key: 'date_depot_dossier',
      type: 'date',
      editableType: 'datetime',
      editable: true
    },
    {
      key: 'depot_stockage',
      type: 'text',
      editableType: 'input',
      editable: true
    },
    {
      key: 'revenu_fiscal_ref',
      type: 'text',
      editableType: 'input',
      editable: true
    },
    { key: 'email', type: 'text', editableType: 'input', editable: true },
    { key: 'source', type: 'text', editableType: 'input', editable: true },
    {
      key: 'date_creation_de_la_fiche',
      type: 'date',
      editableType: 'datetime',
      editable: true
    },
    { key: 'type_lead', type: 'text', editableType: 'input', editable: true },
    {
      key: 'commentaire',
      type: 'text',
      editableType: 'input',
      editable: true
    },
    {
      key: 'zone_climatique',
      type: '',
      editableType: 'select',
      editable: true,
      optionsSelect: [
        { full_name: 'H1' },
        { full_name: 'H2' },
        { full_name: 'H3' }
      ]
    },
    {
      key: 'previsite_date',
      type: 'date',
      editableType: 'datetime',
      editable: true
    },
    {
      key: 'dossier_mpr',
      type: '',
      editableType: 'select',
      editable: true,
      optionsSelect: [{ full_name: 'Non' }, { full_name: 'Oui' }]
    },
    {
      key: 'type_isolation',
      editable: true,
      type: 'text',
      editableType: 'input'
    },
    {
      key: 'numero_avoir',
      editable: true,
      type: 'text',
      editableType: 'input'
    },
    {
      key: 'date_avoir',
      editable: true,
      type: 'date',
      editableType: 'datetime'
    },
    {
      key: 'montant_ttc_avoir',
      editable: true,
      type: 'text',
      editableType: 'input'
    },
    {
      key: 'montant_tva_avoir',
      editable: true,
      type: 'text',
      editableType: 'input'
    },
    {
      key: 'cofrac_bureau_de_control',
      editable: true,
      type: 'text',
      editableType: 'input'
    },
    {
      key: 'dossier_ID',
      editable: false
    },
    {
      key: 'BE_TYPE',
      editableType: 'select',
      editable: true,
      api: true,
      getters: 'getSettingFilialesTh',
      formate: true
    },
    {
      key: 'statue_date',
      editable: false,
      type: 'date',
      editableType: 'datetime'
    },
    {
      key: 'coef_prime_installateur',
      type: 'text',
      editableType: 'input',
      editable: true
    }
  ]
};
const getters = {
  getDataRapportTh: state => state.dataRapportTh,
  getLoaderRapportTh: state => state.loaderRapportTh,
  getErrorRapportTh: state => state.errorRapportTh,
  getLoaderFilterRapportTh: state => state.loaderFilterRapportTh,
  getErrorFilterRapportTh: state => state.errorFilterRapportTh,
  getFilterRapportThDateDepotDossier: state =>
    state.filterRapportThDateDepotDossier,
  getFilterRapportThDepot: state => state.filterRapportThDepot,
  getFilterRapportThGruopes: state => state.filterRapportThGruopes,
  getFilterRapportThOrganisme: state => state.filterRapportThOrganisme,
  getFilterRapportThStatut: state => state.filterRapportThStatut,
  getLoaderStatisticRapportTh: state => state.loaderStatisticRapportTh,
  getErrorStatisticRapportTh: state => state.errorStatisticRapportTh,
  getDataStatisticRapportTh: state => state.dataStatisticRapportTh,
  getTotalRapportTh: state => state.totalRapportTh,
  getDataStatisticChartRapportTh: state => state.dataStatisticChartRapportTh,
  getFieldPropertyRapportTh: state => key => {
    let data = state.fieldsAnalyseConfig.filter(i => i.key == key);
    if (data.length) {
      if (data[0].api) {
        var optionsSelect = store.getters[data[0].getters];
        if (data[0].formate) {
          optionsSelect = optionsSelect.map(item => ({
            full_name: item.name
          }));
        }
        return {
          ...data[0],
          optionsSelect: optionsSelect
        };
      }
      return data[0];
    }
    return {};
  },
  getLoaderExportDataRapportTh: state => state.loaderExportDataRapportTh
};
const mutations = {
  SET_LOADER_DATA_RAPPORT_TH(state, payload) {
    state.loaderRapportTh = payload;
  },
  SET_ERROR_DATA_RAPPORT_TH(state, payload) {
    state.errorRapportTh = payload;
  },
  SET_LOADER_DATA_FILTER_RAPPORT_TH(state, payload) {
    state.loaderFilterRapportTh = payload;
  },
  SET_DATA_RAPPORT_TH(state, payload) {
    if (payload.grouped) {
      state.dataRapportTh = payload.data.map(item =>
        GroupeRowThNew.create(item)
      );
    } else {
      state.dataRapportTh = payload.data.map(item => RowThNew.create(item));
    }
  },
  SET_DATA_TOTAL_RAPPORT_TH(state, payload) {
    state.totalRapportTh = payload;
  },
  SET_DATA_FILTER_RAPPORT_TH(state, payload) {
    state.filterRapportThDateDepotDossier = payload.date_depot_dossier;
    state.filterRapportThDepot = payload.depot;
    state.filterRapportThGruopes = payload.groupes;
    state.filterRapportThOrganisme = payload.organisme;
    state.filterRapportThStatut = payload.statut;
  },
  SET_ERROR_DATA_FILTER_RAPPORT_TH(state, payload) {
    state.errorFilterRapportTh = payload;
  },
  SET_LOADER_DATA_STATISTIC_RAPPORT_TH(state, payload) {
    state.loaderStatisticRapportTh = payload;
  },
  SET_ERROR_DATA_STATISTIC_RAPPORT_TH(state, payload) {
    state.errorStatisticRapportTh = payload;
  },
  SET_DATA_STATISTIC_TH(state, payload) {
    state.dataStatisticRapportTh.ADP = payload.ADP;
    state.dataStatisticRapportTh.facture_edite = payload.facture_edite;
    state.dataStatisticRapportTh.paye = payload.paye;
    state.dataStatisticRapportTh.total_montant_final =
      payload.total_montant_final;
    // state.dataStatisticChartRapportTh.total_montant_final =
    //   payload.total_montant_final;
    state.dataStatisticChartRapportTh.total_montant_final_ADP =
      payload.total_montant_final_ADP;
    state.dataStatisticChartRapportTh.total_montant_final_facture_edite =
      payload.total_montant_final_facture_edite;
    state.dataStatisticChartRapportTh.total_montant_final_paye =
      payload.total_montant_final_paye;
  },
  SET_LOADER_UPDATE_COLUMN_RAPPORT_TH(state, payload) {
    state.loaderUpdateColumnRapportTh = payload;
  },
  SET_DATA_UPDATE_COLUMN_RAPPORT_TH(state, payload) {
    payload.row.update(payload.response);
  },
  SET_LOCK_PROJECTS_NEWS_TH(state, payload) {
    payload.response.map(item => {
      let index = payload.data.findIndex(i => i.id == item.id);
      payload.data[index].montant_min_lock = item.montant_min_lock;
    });
  },
  SET_LOADING_EXPORT_DATA_RAPPORT_TH(state, payload) {
    state.loaderExportDataRapportTh = payload;
  }
};
const actions = {
  async fetchDataForFilterRapportTh({ commit }, payload) {
    commit('SET_LOADER_DATA_FILTER_RAPPORT_TH', true);
    try {
      if (requestanaluseValidationDataFilter) {
        // commit('SET_LOADER_DATA_FILTER_RAPPORT_TH', false);
        requestanaluseValidationDataFilter.cancel('cancel-request');
      }
      requestanaluseValidationDataFilter = axios.CancelToken.source();

      const response = await axios.get(
        domain + `/api/get_depots_organismes_statuts`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload,
          cancelToken: requestanaluseValidationDataFilter.token
        }
      );
      commit('SET_DATA_FILTER_RAPPORT_TH', response.data.data);
      commit('SET_LOADER_DATA_FILTER_RAPPORT_TH', false);
    } catch (error) {
      commit('SET_ERROR_DATA_FILTER_RAPPORT_TH', error?.response?.date?.error);
      commit('SET_LOADER_DATA_FILTER_RAPPORT_TH', false);
    }
  },
  async fetchDataForRapportTh({ commit }, payload) {
    commit('SET_LOADER_DATA_RAPPORT_TH', true);
    try {
      if (requestanaluseValidationData) {
        // commit('SET_LOADER_DATA_RAPPORT_TH', false);
        requestanaluseValidationData.cancel('cancel-request');
      }
      requestanaluseValidationData = axios.CancelToken.source();
      const response = await axios.get(domain + `/api/projects`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: payload,
        cancelToken: requestanaluseValidationData.token
      });
      commit('SET_DATA_RAPPORT_TH', {
        data: payload.grouped ? response.data.data : response.data.data.data,
        grouped: payload.grouped
      });
      commit(
        'SET_DATA_TOTAL_RAPPORT_TH',
        payload.grouped ? response.data.total : response.data.data.total
      );
      commit('SET_LOADER_DATA_RAPPORT_TH', false);
    } catch (error) {
      commit('SET_ERROR_DATA_RAPPORT_TH', error?.response?.date?.error);
      commit('SET_LOADER_DATA_RAPPORT_TH', false);
    }
  },
  async fetchStatisticDataForRapportTh({ commit }, payload) {
    commit('SET_LOADER_DATA_STATISTIC_RAPPORT_TH', true);
    try {
      if (requestanaluseValidationDataStatistic) {
        // commit('SET_LOADER_DATA_STATISTIC_RAPPORT_TH', false);
        requestanaluseValidationDataStatistic.cancel('cancel-request');
      }
      requestanaluseValidationDataStatistic = axios.CancelToken.source();

      const response = await axios.get(domain + `/api/projects_statistics`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: payload,
        cancelToken: requestanaluseValidationDataStatistic.token
      });
      commit('SET_DATA_STATISTIC_TH', response.data.data);
      commit('SET_LOADER_DATA_STATISTIC_RAPPORT_TH', false);
    } catch (error) {
      commit(
        'SET_ERROR_DATA_STATISTIC_RAPPORT_TH',
        error?.response?.date?.error
      );
      commit('SET_LOADER_DATA_STATISTIC_RAPPORT_TH', false);
    }
  },
  async updateDataInProjetTh({ commit }, payload) {
    if (payload.column == 'agent_commercial_terrain') {
      payload.value = payload.value.id;
    }
    commit('SET_LOADER_UPDATE_COLUMN_RAPPORT_TH', true);
    try {
      const response = await axios.post(
        domain +
        `/api/project_update?id=${payload.row.id}&column=${payload.column}&value=${payload.value}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_DATA_UPDATE_COLUMN_RAPPORT_TH', {
        row: payload.row,
        response: response.data.data
      });
      commit('SET_LOADER_UPDATE_COLUMN_RAPPORT_TH', false);
      return true;
    } catch (error) {
      commit('SET_LOADER_UPDATE_COLUMN_RAPPORT_TH', false);
      return false;
    }
  },
  async changeLockProjectsTh({ commit, state }, payload) {
    var bodyFormData = new FormData();
    let table = payload
      ? state.dataRapportTh
        .map(item => {
          return item.projects.filter(i => i.check == true);
        })
        .flat()
      : state.dataRapportTh.filter(item => item.check == true);
    for (let i = 0; i < table.length; i++) {
      bodyFormData.append('ids[' + [i] + ']', table[i].id);
    }
    try {
      const responce = await axios.post(
        domain + `/api/projects_lock`,
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LOCK_PROJECTS_NEWS_TH', {
        response: responce.data.data,
        data: table
      });
      return { succes: true };
    } catch (error) {
      if (error?.responce?.data?.error) {
        return { succes: false, error: error.responce.data.error };
      } else {
        return { succes: false, error: ['Une erreur est servenue'] };
      }
    }
  },
  async changeStatutInAnalyseValidation({ commit, state }, payload) {
    var bodyFormData = new FormData();
    if (payload.statut != null) {
      bodyFormData.append('type', payload.statut);
    }
    if (payload.date != null) {
      bodyFormData.append('date', payload.date);
    }
    let table = payload.grouped
      ? state.dataRapportTh
        .map(item => {
          return item.projects.filter(i => i.check == true);
        })
        .flat()
      : state.dataRapportTh.filter(item => item.check == true);
    for (let i = 0; i < table.length; i++) {
      bodyFormData.append('ids[' + [i] + ']', table[i].id);
    }
    try {
      const responce = await axios.post(
        domain + `/api/project_pay`,
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { succes: true, data: responce.data };
    } catch (error) {
      if (error?.responce?.data?.error) {
        return { succes: false, error: error.responce.data.error };
      } else {
        return { succes: false, error: ['Une erreur est servenue'] };
      }
    }
  },
  async exportFileNewRapportTh({ commit }, payload) {
    const params = { annee: payload.annee };
    try {
      if (payload.date_fin != null) {
        params.date_fin = payload.date_fin;
      }
      if (payload.date_debut != null) {
        params.date_debut = payload.date_debut;
      }
      if (payload.statut != null && payload.statut) {
        params.statut = payload.statut;
      }
      if (payload.numbers != null && payload.numbers) {
        params.numbers = payload.numbers;
      }
      if (payload.ids != null && payload.ids) {
        params.ids = payload.ids;
      }
      if (payload.search != null && payload.search) {
        params.search = payload.search;
      }
      if (payload.locked != null && payload.locked) {
        params.locked = payload.locked;
      }
      if (payload.depots != null && payload.depots) {
        params.depots = payload.depots;
      }
      if (payload.organismes != null && payload.organismes) {
        params.organismes = payload.organismes;
      }
      if (payload.organismes != null && payload.view) {
        params.view = payload.view;
      }
      const response = await axios.get(domain + `/api/projects_export`, {
        params: params,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      // const link = document.createElement('a');
      // link.href = window.URL.createObjectURL(new Blob([response.data]));
      // link.setAttribute('download', 'Compil-TH'+payload?.date_debut+'-'.payload.date_fin+'xlsx');
      // document.body.appendChild(link);
      // link.click();
      // commit('SET_LOADING_EXPORT_DATA_RAPPORT_TH', false);
      this.state.showNotifExportFile = true;
      return { success: true, msg: response.data };
    } catch (error) {
      return { success: false };
    }
  },
  async uploadFileExcelNumDossier({ commit, dispatch }, payload) {
    commit('SET_LOADING_TH', true);
    commit('SET_ERROR_TH', null);
    var bodyFormData = new FormData();
    bodyFormData.append('file', payload);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${this.state.User.access_token}`
      }
    };
    try {
      const response = await axios.post(
        domain + '/api/projects_get_numbers_from_excel',
        bodyFormData,
        config
      );
      commit('SET_LOADING_TH', false);
      return { succes: true, data: response.data.data };
    } catch (error) {
      commit('SET_LOADING_TH', false);
      if (
        error.response &&
        error.response.data.extra &&
        error.response.data.missing
      ) {
        commit('SET_ERROR_TH', {
          extra: error.response.data.extra,
          missing: error.response.data.missing
        });
      } else {
        commit('SET_ERROR_TH', error.response.data.error);
      }
      return { succes: false };
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
