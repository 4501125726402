import produitFactureLibre from './produitFactureLibreTh';
export default class FactureLibreTh {
  constructor(data) {
    this.id = data.id;
    this.type = data.type;
    this.num = data.num;
    this.showDetails = false;
    this.categorie_id = data.categorie_id;
    this.categorie = data.categorie;
    this.etat = data.etat;
    this.famille = data.famille;
    this.siren_tva = data.siren_tva;
    this.siren_value = data.siren_value;
    this.tva_value = data.tva_value;
    this.objet_color = data.objet_color;
    this.text_color = data.text_color;
    this.files = data.files;
    this.historique = data.logs;
    this.id_vendeur = data.vendeur_id;
    this.vendeur_id = data.vendeur_id;
    this.id_acheteur = data.id_acheteur;
    this.total_net_ht = data.total_net_ht;
    this.statut_client = data.statut_client;
    this.user = data.user;
    this.user_id = data.user_id;
    this.total_ht = data.total_ht;
    this.total_tva = data.total_tva;
    this.total_ttc = data.total_ttc;
    this.total_ht_resource = data.total_ht_resource;
    this.total_tva_resource = data.total_tva_resource;
    this.total_ttc_resource = data.total_ttc_resource;
    this.num_proforma = data.num_proforma;
    this.confirme = data.confirme;
    this.total_ttc_apres_reduction = data.total_ttc_apres_reduction;
    this.total_ht_apres_reduction = data.total_ht_apres_reduction;
    this.pu_ht_apres_reduction = data.pu_ht_apres_reduction;
    this.check = false;
    this.date_paiement = data.date_paiement;
    this.date_creation = data.date_creation;
    this.vendeur = data.vendeur;
    this.acheteur = data.acheteur;
    this.openSelect = false;
    this.openSelectClient = false;
    this.type_avoir = data.type_avoir;
    this.template_id = data.template_id;
    this.FactureAvoir = data.FactureAvoir.map(item => {
      return {
        ...item,
        checkAvoir: false,
        downloadPDF: false,
        transferePro: false
      };
    });
    this.checkGlobale = false;
    this.reste = data.reste;
    this.client_id = data.client_id;
    this.reglements = data.reglements;
    this.date_echeance = data.date_echeance;
    this.montant_regle = data.montant_regle;
    this.date_paiement = data.date_paiement;
    this.client = data.acheteur;
    this.ttc = data.ttc;
    this.belongsTo = data.belongsTo;
    this.historique = data.logs;
    this.motif_avoir = data.motif_avoir;
    this.downloadPDF = false;
    this.transferePro = false;
  }
  updateStatue(data) {
    this.etat = data.etat;
    this.date_paiement = data.date_paiement;
    this.statut_client = data.statut_client;
  }
  updateStatueClient(data) {
    this.statut_client = data.statut_client;
  }
  updateFilesFactures(data) {
    this.files = data;
  }
  addFilesFacture(data) {
    data.map(item => {
      this.files.unshift(item);
    });
  }
  update(data) {
    this.client_id = data.client_id;
    this.type = data.type;
    this.numero = data.numero;
    this.files = data.files;
    this.dateCreation = data.dateCreation;
    this.categorie_id = data.categorie_id;
    this.categorie = data.categorie;
    this.famille = data.famille;
    this.etat = data.etat;
    this.confirme = data.confirme;
    this.siren_tva = data.siren_tva;
    this.siren_value = data.siren_value;
    this.tva_value = data.tva_value;
    this.user = data.user;
    this.statut_client = data.statut_client;
    this.id_vendeur = data.vendeur_id;
    this.vendeur_id = data.vendeur_id;
    this.id_acheteur = data.id_acheteur;
    this.historique = data.logs;
    this.check = data.check;
    this.objet_color = data.objet_color;
    this.text_color = data.text_color;
    this.user_id = data.user_id;
    this.num_proforma = data.num_proforma;
    this.total_ht = data.total_ht;
    this.total_net_ht = data.total_net_ht;
    this.total_tva = data.total_tva;
    this.total_ttc = data.total_ttc;
    this.total_ht_resource = data.total_ht_resource;
    this.total_tva_resource = data.total_tva_resource;
    this.total_ttc_resource = data.total_ttc_resource;
    this.total_ttc_apres_reduction = data.total_ttc_apres_reduction;
    this.total_ht_apres_reduction = data.total_ht_apres_reduction;
    this.pu_ht_apres_reduction = data.pu_ht_apres_reduction;
    this.date_paiement = data.date_paiement;
    this.vendeur = data.vendeur;
    this.acheteur = data.acheteur;
    this.type_avoir = data.type_avoir;
    this.template_id = data.template_id;
    this.FactureAvoir = data.FactureAvoir.map(item => {
      return { ...item, checkAvoir: false };
    });
    this.FactureAvoir = data.FactureAvoir;
    this.historique = data.logs;
  }
  updateDataReglement(data) {
    this.reste = data.reste;
    this.reglements = data.reglements;
    this.date_echeance = data.date_echeance;
    this.montant_regle = data.montant_regle;
    this.date_paiement = data.date_paiement;
    this.payment_type = data.payment_type;
    this.etat = data.etat;
    this.statut_client = data.statut_client;
  }
  static create(data) {
    return new FactureLibreTh(data);
  }
}
