import axios from 'axios';
import domain from '@/environment';
import ficheStandard from '../../models/FicheStandar';
import fichePouce from '../../models/FichePouce';
let cancelRequestFicheCoupeDePouce = null;
let cancelRequestFicheStandard = null;
const state = {
  ficheStandards: null,
  fichePouces: null
};
const getters = {
  getFicheStandard: state => state.ficheStandards,
  getFichePouce: state => state.fichePouces
};
const mutations = {
  NEW_STANDAR_FICHE(state, payload) {
    state.ficheStandards = ficheStandard.create(payload);
  },
  UPDATE_FICHE_STANDARD(state, payload) {
    payload.row.update(payload.response);
  },
  NEW_POUCE_FICHE(state, payload) {
    state.fichePouces = fichePouce.create(payload);
  }
};
const actions = {
  async fetchFicheStandard({ commit }) {
    commit('SET_LOADING', true);
    try {
      if (cancelRequestFicheStandard) {
        cancelRequestFicheStandard.cancel('cancel-request');
      }
      cancelRequestFicheStandard = axios.CancelToken.source();
      const response = await axios.get(domain + `/api/ficheStandard`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        cancelToken: cancelRequestFicheStandard.token
      });
      commit('NEW_STANDAR_FICHE', response.data.ficheStandard);
      commit('SET_LOADING', false);
      return true;
    } catch (error) {
      commit('SET_LOADING', false);
      return false;
    }
  },
  async fetchFichePouce({ commit }) {
    commit('SET_LOADING', true);
    try {
      if (cancelRequestFicheCoupeDePouce) {
        cancelRequestFicheCoupeDePouce.cancel('cancel-request');
      }
      cancelRequestFicheCoupeDePouce = axios.CancelToken.source();
      const response = await axios.get(domain + `/api/ficheCoupDePouce`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        cancelToken: cancelRequestFicheCoupeDePouce.token
      });
      commit('NEW_POUCE_FICHE', response.data.ficheCoupDePouce);
      commit('SET_LOADING', false);
      return true;
    } catch (error) {
      commit('SET_LOADING', false);
      return false;
    }
  },
  async editFicheStandard({ commit }, payload) {
    commit('SET_LOADING', true);
    const body = payload.row;
    const champName = payload.column;
    body[champName] = payload.value;
    try {
      const response = await axios.post(
        domain + `/api/editFicheStandard`,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_FICHE_STANDARD', {
        ficheStandard: payload,
        response: response.data.ficheStandard
      });
      commit('SET_LOADING', false);
      return true;
    } catch (error) {
      commit('SET_LOADING', false);
      return false;
    }
  },
  async editFichePouce({ commit }, payload) {
    commit('SET_LOADING', true);
    const body = payload.row;
    const champName = payload.column;
    body[champName] = payload.value;
    try {
      const response = await axios.post(
        domain + `/api/editFicheCoupDePouce`,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_FICHE_STANDARD', {
        ficheStandard: payload,
        response: response.data.ficheStandard
      });
      commit('SET_LOADING', false);
      return true;
    } catch (error) {
      commit('SET_LOADING', false);
      return false;
    }
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
