import axios from 'axios';
import Bank from '../../models/Bank';
import domain from '@/environment';
let prefixBank = 'filiale';
let requestBank = null;

const state = {
  bankRows: [],
  bankLoading: false,
  bankError: [],
  bankTotalRows: 0
};
const getters = {
  getBankRows: state => state.bankRows,
  getBankLoading: state => state.bankLoading,
  getBankError: state => state.bankError,
  getBankTotalRows: state => state.bankTotalRows
};
const mutations = {
  SET_BANK_ROWS(state, payload) {
    state.bankRows = payload.map(bank => Bank.create(bank));
  },
  SET_LOADING_BANK(state, payload = false) {
    state.bankLoading = payload;
  },
  SET_ERROR_BANK(state, payload) {
    state.bankError = payload;
  },
  ADD_NEW_BANK(state, payload) {
    state.bankRows.unshift(Bank.create(payload));
  },
  UPDATE_BANK(state, payload) {
    payload.item.update(payload.response);
  },
  SET_TOTAL_ROWS_BANK(state, payload) {
    state.bankTotalRows = payload;
  }
};
const actions = {
  async getAllBanks({ commit }, payload) {
    commit('SET_LOADING_BANK', true);
    let params = {};
    // if (payload?.page != null) {
    //   params.page = payload.page;
    // }
    // if (payload?.per_page != null) {
    //   params.per_page = payload.per_page;
    // }
    if (payload?.search != null && payload?.search != '') {
      params.search = payload.search;
    }
    try {
      if (requestBank) {
        commit('SET_ERROR_BANK', null);
        requestBank.cancel('cancel-request');
      }
      requestBank = axios.CancelToken.source();

      const response = await axios.get(
        domain + '/api/' + prefixBank + '/all-banks',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          cancelToken: requestBank.token
        }
      );
      // commit('SET_TOTAL_ROWS_BANK', response.data.data.total);
      commit('SET_BANK_ROWS', response.data);
      commit('SET_LOADING_BANK', false);
    } catch (error) {
      if (!error.message == 'cancel-request') {
        commit('SET_LOADING_BANK', false);
        commit('SET_ERROR_BANK', ['Une erreur est survenue']);
      }
    }
  },
  async createBnak({ commit }, payload) {
    commit('SET_ERROR_BANK', []);
    try {
      const response = await axios.post(
        domain + `/api/${prefixBank}/add-bank`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_NEW_BANK', response.data.data);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.name
      ) {
        commit('SET_ERROR_BANK', error.response.data.errors.name);
      } else {
        if (error && error.response && error.response.data) {
        } else {
          commit('SET_ERROR_BANK', ['Une erreur est survenue']);
        }
      }
    }
    return false;
  },
  async updateBank({ commit }, payload) {
    commit('SET_ERROR_BANK', []);
    try {
      const response = await axios.post(
        domain + `/api/${prefixBank}/edit-bank`,
        payload.bank,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_BIEN', {
        item: payload.bankOrigin,
        response: response.data.data
      });

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.name
      ) {
        commit('SET_ERROR_BANK', error.response.data.errors.name);
      } else {
        if (error && error.response && error.response.data) {
        } else {
          commit('SET_ERROR_BANK', ['Une erreur est survenue']);
        }
      }
      return false;
    }
  },
  async exporXlsBanque({ commit }) {
    commit('SET_LOADING_BANK', true);

    try {
      const response = await axios.get(domain + '/api/filiale/export_bank', {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        responseType: 'blob'
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'Liste_des_banques' + '.xlsx');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_BANK');

      return true;
    } catch (error) {
      commit('SET_LOADING_BANK');

      return false;
    }
  },
  resetVarBank({ commit }) {
    commit('SET_ERROR_BANK', []);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
