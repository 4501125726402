import axios from 'axios';
import domain from '@/environment';
let requestPixelFact = null;
import Pixel from '../../models/Pixel';

export default {
    state: {
        pixelFacture: [],
        loaderPixelFacture: false,
        errorPixelFacture: [],
        rowsPixel: 0,

    },
    getters: {
        getPixelFacture: state => state.pixelFacture,
        getLoaderPixelFacture: state => state.loaderPixelFacture,
        getErrorPixelFacture: state => state.errorPixelFacture,
        TotalRowsPixel: state => state.rowsPixel,

    },
    mutations: {
        SET_PIXEL_FACT_LOADER(state, payload) {
            state.loaderPixelFacture = payload;
        },
        SET_PIXEL_FACT(state, payload) {
            state.pixelFacture = payload.map(i => Pixel.create(i));
        },
        SET_ERROR_PIXEL_FACT(state, payload) {
            state.errorPixelFacture = payload;
        },
        SET_TOTAL_ROWS_PIXEL(state, payload) {
            state.rowsPixel = payload;
        },
    },
    actions: {
        async getAllPixelFacture({ commit }, payload) {
            commit('SET_PIXEL_FACT_LOADER', true);
            const params = {
                per_page: payload.per_page,
                page: payload.page,
                famille: payload.famille,
                column: payload.column,
                order: payload.order,
                groupedPixel: payload.groupedPixel
            };
            if (payload.search != null) {
                params.num = payload.search;
            }
            if (payload.date_debut != null) {
                params.date_debut = payload.date_debut;
            }
            if (payload.date_fin != null) {
                params.date_fin = payload.date_fin;
            }
            if (payload.lots.length) {
                params.lots = payload.lots
            }
            if (payload.organisme.length) {
                params.organisme = payload.organisme.map(i => i.id);
            }
            try {
                if (requestPixelFact) {
                    commit('SET_ERROR_PIXEL_FACT', null);
                    requestPixelFact.cancel('cancel-request');
                }
                requestPixelFact = axios.CancelToken.source();
                const response = await axios.get(
                    domain + `/api/libre/factures`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.state.User.access_token}`
                        },
                        params: params,
                        cancelToken: requestPixelFact.token
                    }
                );
                commit('SET_PIXEL_FACT', response.data.data);
                commit('SET_TOTAL_ROWS_PIXEL', response.data.count);
                commit('SET_PIXEL_FACT_LOADER', false);
                return true;
            } catch (error) {
                if (
                    error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.error
                ) {
                    commit('SET_PIXEL_FACT_LOADER', error.response.data.error);
                    commit('SET_LOADING_FACTURES_MENSUEL', false);
                } else {
                    if (!error.message == 'cancel-request') {
                        commit('SET_LOADING_FACTURES_MENSUEL', false);
                        commit('SET_PIXEL_FACT_LOADER', ['Une erreur est survenue']);
                    }
                }
                return false;
            }
        },
        async recupereFacturePixel({ commit }, payload) {

            try {
                const response = await axios.post(
                    domain + `/api/facture/generateFacturePixel`,
                    payload, {
                    headers: {
                        Authorization: `Bearer ${this.state.User.access_token}`
                    }
                }
                );

                return { succes: true, data: response.data }

            } catch (error) {
                if (error?.response?.data?.error) {
                    return { succes: false, error: error.response.data.error }
                }
                return { succes: false, error: ['Une erreur est servenue'] }
            }
        },
        resetPixelFacture({ commit }, payload) {
            commit('SET_ERROR_PIXEL_FACT', payload);
        }
    }
};
