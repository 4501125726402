import axios from 'axios';
import domain from '@/environment';

const state = {
    societeFacturesOfFiliale: [],
    societeFactureErrors: null,
    societeFactureLoader: false,
};
const getters = {
    getSocieteFacturesOfFiliale: state => state.societeFacturesOfFiliale,
    getSocieteFactureLoader: state => state.societeFactureLoader,
    getSocieteFactureErrors : state => state.societeFactureErrors

};
const mutations = {
  SET_SOCIETE_FACTURE_LOADER(state, payload) {
    state.societeFactureLoader = payload;
  },
  SET_SOCIETE_FACTURE_ERRORS(state, payload) {
    state.societeFactureErrors = payload;
  },
  SET_LIST_SOCIETE_FACTURE_OF_FILIALE(state, payload) {
    if (payload.add) {
      let tab = [
        ...payload.response,
        ...state.societeFacturesOfFiliale.filter(
          item =>
            !item.id &&
            !(
              item.filiale_id == payload.societeFacture.filiale_id &&
              item.societe_id == payload.societeFacture.societe_id
            )
        )
      ];

      state.societeFacturesOfFiliale = tab;
    } else {
      state.societeFacturesOfFiliale = payload;
    }
  }
};
const actions = {
  async addSocieteFactureToFiliale({ commit }, payload) {
    const params = {
      filiale_id: payload.filiale_id,
      societe_id: payload.societe_id,
      favori: payload.favori
    };
    try {
      const response = await axios.post(
        domain + `/api/filiale/add_societe_facture`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_SOCIETE_FACTURE_OF_FILIALE', {
        response: response.data.data,
        add: true,
        societeFacture: payload.societeFacture
      });
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_SOCIETE_FACTURE_ERRORS', error.response.data.error);
      } else {
        commit('SET_SOCIETE_FACTURE_ERRORS', ['Une erreur est survenue']);
      }
      return false;
    }
  },
  async removeFilialeSocieteFacture({ commit }, payload) {
    commit('SET_SOCIETE_FACTURE_ERRORS', null);
    try {
      const response = await axios.delete(
        domain + `/api/filiale/delete-societe-facture/` + payload.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_SOCIETE_FACTURE_ERRORS', error.response.data);
      } else {
        commit('SET_SOCIETE_FACTURE_ERRORS', 'Une erreur est survenue');
      }
      return false;
    }
  },
  async updateSocieteFactureFiliale({ commit }, payload) {
    const params = {
      id: payload.id,
      societe_id: payload.societe_id,
      favori: payload.favori
    };
    try {
      const response = await axios.post(
        domain + `/api/filiale/edit-filiale-societe-facture`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_SOCIETE_FACTURE_OF_FILIALE', response.data.data);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_SOCIETE_FACTURE_ERRORS', error.response.data.error);
      } else {
        commit('SET_SOCIETE_FACTURE_ERRORS', ['Une erreur est survenue']);
      }
      return false;
    }
  },
  async getAllSocieteFactureOfFiliale({ commit }, payload) {
    commit('SET_SOCIETE_FACTURE_LOADER', true);
    try {
      const response = await axios.get(
        domain + `/api/filiale/all-societe-factures?filiale_id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_SOCIETE_FACTURE_OF_FILIALE', response.data.data);
      commit('SET_SOCIETE_FACTURE_LOADER', false);
      return true;
    } catch (error) {
      if (error?.response?.data) {
        commit('SET_SOCIETE_FACTURE_ERRORS', error.response.data.message);
      } else {
        commit('SET_SOCIETE_FACTURE_ERRORS', 'Une erreur est survenue');
      }
      commit('SET_SOCIETE_FACTURE_LOADER', false);
      return false;
    }
  },
  resteModalOfSocieteFacture({ commit }, payload) {
    commit('SET_SOCIETE_FACTURE_ERRORS', null);
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
