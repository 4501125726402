import axios from 'axios';
import domain from '@/environment';
import FactureReglement from '../../models/FactureReglement';
import Reglements from '../../models/Reglements';
let requestReglement = null;
let requestClient = null;

const state = {
  clients: [],
  facturesReglement: [],
  solde: null,
  reglements: [],
  reglementTotalRows: null,
  loaderReglementRows: false,
  errorReglement: [],
  clientFilter: [],
  soldeEcheance: null,
  loaderClientReglementList: false,
  acheteurReglement: [],
  amountNonLettrerReglemnt: null,
  amountNonLettrerReglemntLoader: false
};
const getters = {
  clientsReglement: state => state.clients,
  facturesReglement: state => state.facturesReglement,
  soldeClient: state => state.solde,
  reglements: state => state.reglements,
  getReglementTotalRows: state => state.reglementTotalRows,
  getLoaderReglementRows: state => state.loaderReglementRows,
  errorReglements: state => state.errorReglement,
  clientFilter: state => state.clientFilter,
  soldeEcheance: state => state.soldeEcheance,
  getLoaderClientReglementList: state => state.loaderClientReglementList,
  getAcheteurReglement: state => state.acheteurReglement,
  getAmountNonLettrerReglemnt: state => state.amountNonLettrerReglemnt,
  getConditionAmountNonLettre: state => {
    return (
      state.amountNonLettrerReglemntLoader == false &&
      state.amountNonLettrerReglemnt != null &&
      state.amountNonLettrerReglemnt != 0
    );
  }
};
const mutations = {
  SET_ACHETEUR_REGLEMENTS(state, payload) {
    state.acheteurReglement = payload;
  },
  ADD_ECHEANCE_TO_REGLEMENT(state, payload) {
    state.reglements.map(element => {
      if (element.id == payload.reglement) {
        element.echeances = payload.response.map(item => {
          return { ...item, check: false };
        });
      }
    });
  },
  SET_SOLDE_ECHEANCE_CLIENT(state, payload) {
    state.soldeEcheance = payload;
  },
  DELETE_REGLEMENT(state, payload) {
    let index = state.reglements.findIndex(item => item.id == payload);
    state.reglements.splice(index, 1);
  },
  SET_ALL_CLIENTS_REGLEMENT(state, payload) {
    state.clients = payload;
  },
  SET_FACTURES_FILIALE_REGLEMENT(state, payload) {
    state.facturesReglement = payload.map(item =>
      FactureReglement.create(item)
    );
  },
  RESET_FACTURES_REGLEMENT(state, payload) {
    state.facturesReglement = payload;
  },
  ADD_NEW_REGLEMENT(state, payload) {
    state.reglements.unshift(Reglements.create(payload));
  },
  SET_SOLDE_CLIENT(state, payload) {
    state.solde = payload;
  },
  SET_AMOUNT_NON_LITTRER(state, payload) {
    state.amountNonLettrerReglemnt = payload;
  },
  SET_AMOUNT_NON_LITTRER_LOADER(state, payload) {
    state.amountNonLettrerReglemntLoader = payload;
  },
  SET_ALL_REGLEMENT(state, payload) {
    state.reglements = payload.map(item => Reglements.create(item));
  },
  SET_TOTAL_ROWS_REGLEMENT(state, payload) {
    state.reglementTotalRows = payload;
  },
  SET_LOADER_REGLEMENT_ROWS(state, payload) {
    state.loaderReglementRows = payload;
  },
  UPDATE_REGLEMENT(state, payload) {
    payload.old.update(payload.response);
  },
  SET_ERROR_REGLEMENT(state, payload) {
    state.errorReglement = payload;
  },
  SET_CLIENTS_REGLEMENT(state, payload) {
    state.clientFilter = payload;
  },
  UPDATE_FILES_REGLEMENT(state, payload) {
    payload.oldReglement.updateFilesReglements(payload.response);
  },
  ADD_NEW_FILES_REGLEMENT(state, payload) {
    let reglement = null;
    state.reglements.map(item => {
      if (item.id == payload.payment_id) {
        reglement = item;
      }
    });
    reglement.updateFilesReglements(payload.response);
  },
  UPDATE_FACTURES_REGLEMENTS(state, payload) {
    payload.oldFactures.map(old => {
      let index = payload.factures.findIndex(facture => facture.id == old.id);
      old.update(payload.factures[index]);
    });
  },
  SET_LOADER_CLIENT_REGLEMENT_LIST(state, payload) {
    state.loaderClientReglementList = payload;
  }
};
const actions = {
  async getAcheteurReglements({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + '/api/reglements/get-current-user-filiales',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_ACHETEUR_REGLEMENTS', response.data);
      return { success: true };
    } catch (error) {
      return { success: false };
    }
  },
  async exportEcheanceReglement({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/reglements/download_lcr_pdf`,
        payload,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (error) {
      commit('SET_ERROR_FACTURE_MENSUEL', ['Une erreur est servenue']);
      return false;
    }
  },
  async changeStatutComptabilise({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/reglements/update-statut-comptabilite`,
        payload.factures,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_FACTURES_REGLEMENTS', {
        factures: response.data.data,
        oldFactures: payload.oldFactures
      });
      return true;
    } catch (error) {
      return false;
    }
  },
  async updatePaymentEcheance({ commit }, payload) {
    try {
      const response = await axios.post(
        `${domain}/api/reglements/update-payment-echeance`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { succes: true, data: response.data.data };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_ERROR_REGLEMENT', data);
      } else {
        commit('SET_ERROR_REGLEMENT', 'Une erreur est survenue');
      }
      return { succes: false };
    }
  },
  async updateFileReglement({ commit }, payload) {
    try {
      const response = await axios.post(
        `${domain}/api/reglements/payment/attached-files/update`,
        payload.bodyFormData,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_FILES_REGLEMENT', {
        response: response.data.data,
        oldReglement: payload.reglement
      });
      commit('SET_ERROR_REGLEMENT', null);
      return { success: true, response: response.data.data };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_ERROR_REGLEMENT', data);
      } else {
        commit('SET_ERROR_REGLEMENT', 'Une erreur est survenue');
      }
      return false;
    }
  },
  async createReglementInInterfaceFacture({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + '/api/reglements/create-payment',
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { succes: true, data: response.data.data };
    } catch (error) {
      return { succes: false };
    }
  },
  async createReglementLcrFacture({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + '/api/reglements/create-payment-echeances/' + payload.id,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { succes: true, data: response.data.data };
    } catch (error) {
      return { succes: false };
    }
  },
  async createReglementLcrFactureEcheances({ commit }, payload) {
    try {
      const response = await axios.post(
        domain +
        '/api/reglements/create-payment-echeances/' +
        payload.response.id,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_ECHEANCE_TO_REGLEMENT', {
        reglement: payload.response.id,
        response: response.data.data
      });
      return { succes: true };
    } catch (error) {
      return { succes: false };
    }
  },
  async getDataReglementInInterfaceFacture({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + '/api/reglements/get-client-payment-condition-type/' + payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      // commit('SET_DATA_REGLEMENT_IN_FACTURE_INTERFACE', response.data.data);
      return { succes: true, data: response.data.data };
    } catch (error) {
      return { succes: false };
    }
  },
  async getAllClientsReglement({ commit }) {
    try {
      const response = await axios.get(
        domain + '/api/reglements/get-clients-all',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_CLIENTS_REGLEMENT', response.data.data);
      return true;
    } catch (error) {
      return false;
    }
  },
  async createReglement({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + '/api/reglements/create-payment',
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_NEW_REGLEMENT', response.data.data);
      return { success: true, response: response.data.data };
    } catch (error) {
      return false;
    }
  },
  async updateReglement({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + '/api/reglements/payment/update',
        payload.bodyFormData,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_REGLEMENT', {
        response: response.data.data,
        old: payload.oldReglement
      });
      return { succes: true, data: response.data.data };
    } catch (error) {
      return { succes: false };
    }
  },
  async deleteAttachementReglement({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + '/api/reglements/payment/remove-attached-file/' + payload.id,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      if (payload.reglement) {
        payload.reglement.removeFiles(payload.id)
      }
      return true;
    } catch (error) {
      return false;
    }
  },
  async AttachFiles({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + '/api/reglements/payment/attached-files',
        payload.bodyFormData,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('ADD_NEW_FILES_REGLEMENT', {
        response: response.data.data,
        payment_id: payload.payment_id
      });

      return { success: true, response: response.data.data };
    } catch (error) {
      return false;
    }
  },
  async annulationPaymentFacture({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + '/api/reglements/annuler-payment-facture',
        payload.bodyFormData,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      if (!payload.source) {
        commit('UPDATE_REGLEMENT', {
          response: response.data.data,
          old: payload.oldReglement
        });
      }
      return { succes: true, data: response.data.data };
    } catch (error) {
      return { succes: false };
    }
  },
  async annulationPayment({ commit }, payload) {
    let params = {};
    if (payload.source) {
      params.source = payload.source;
    }
    try {
      const response = await axios.post(
        domain + '/api/reglements/annuler-payment/' + payload.id,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      if (!payload.source) {
        commit('UPDATE_REGLEMENT', {
          response: response.data.data,
          old: payload.oldReglement
        });
      }
      return { succes: true, data: response.data.data };
    } catch (error) {
      return { succes: false };
    }
  },
  async deleteReglement({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + '/api/reglements/payment-delete/' + payload,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_REGLEMENT', payload);
      return { success: true };
    } catch (error) {
      return { success: false, error: error.response.data.error };
    }
  },
  async getClientsReglement({ commit }, payload) {
    commit('SET_LOADER_CLIENT_REGLEMENT_LIST', true);
    try {
      commit('SET_ALL_CLIENTS_REGLEMENT', []);
      if (requestClient) {
        commit('SET_ERROR_REGLEMENT', null);
        requestClient.cancel('cancel-request');
      }
      requestClient = axios.CancelToken.source();
      const params = {};
      if (payload?.type_partenaire) {
        params.type_partenaire = payload.type_partenaire;
      }
      const response = await axios.get(domain + '/api/reglements/get-clients', {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: requestClient.token
      });
      commit('SET_ALL_CLIENTS_REGLEMENT', response.data.data);
      commit('SET_LOADER_CLIENT_REGLEMENT_LIST', false);

      return true;
    } catch (error) {
      commit('SET_LOADER_CLIENT_REGLEMENT_LIST', false);

      return false;
    }
  },
  resetListPartenaire({ commit }, payload) {
    commit('SET_ALL_CLIENTS_REGLEMENT', payload);
  },
  async getReglements({ commit }, payload) {
    commit('SET_ERROR_REGLEMENT', null);
    commit('SET_LOADER_REGLEMENT_ROWS', true);
    commit('SET_ERROR_REGLEMENT', null);
    const params = {};
    if (payload?.page) {
      params.page = payload.page;
    }
    if (payload?.per_page) {
      params.per_page = payload.per_page;
    }
    if (payload?.type.length) {
      params.type = payload.type.map(item => item.value);
    }
    if (payload?.clients?.length) {
      params.clients = payload.clients.map(item => item.value);
    }
    if (payload?.type_partenaire?.length) {
      params.type_partenaire = payload.type_partenaire.map(item => item.value);
    }
    if (payload?.vendeur?.length) {
      params.vendeur = payload.vendeur.map(item => item.value);
    }
    if (payload?.acheteur?.length) {
      params.acheteur = payload.acheteur.map(item => item.value);
    }
    if (payload?.date_debut) {
      params.date_debut = payload.date_debut;
    }
    if (payload?.date_fin) {
      params.date_fin = payload.date_fin;
    }
    if (payload?.comptabilise) {
      params.comptabilise = payload.comptabilise;
    }
    if (payload?.etat_lettrage) {
      params.etat_lettrage = payload.etat_lettrage;
    }
    if (payload?.num) {
      params.num = payload.num;
    }
    if (payload?.order) {
      params.order = payload.order;
    }
    if (payload?.column) {
      params.column = payload.column;
    }
    if (payload?.etat || payload.etat == 0) {
      params.etat = payload.etat;
    }
    try {
      if (requestReglement) {
        commit('SET_ERROR_REGLEMENT', null);
        requestReglement.cancel('cancel-request');
      }
      requestReglement = axios.CancelToken.source();
      const response = await axios.get(domain + '/api/reglements/payments', {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: requestReglement.token
      });
      if (payload?.per_page) {
        commit('SET_ALL_REGLEMENT', response.data.data.data);
        commit('SET_TOTAL_ROWS_REGLEMENT', response.data.data.total);
      } else {
        commit('SET_ALL_REGLEMENT', response.data.data);
      }
      commit('SET_ERROR_REGLEMENT', null);
      commit('SET_LOADER_REGLEMENT_ROWS', false);

      return { success: true };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_REGLEMENT', error.response.data.error);
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_ERROR_REGLEMENT', ['Une erreur est survenue']);
        }
      }
      commit('SET_LOADER_REGLEMENT_ROWS', false);
      return { success: false };
    }
  },
  async getFactureByFilialeReglement({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + '/api/reglements/factures-non-regles',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      commit('SET_FACTURES_FILIALE_REGLEMENT', response.data.data);
      return true;
    } catch (error) {
      return false;
    }
  },
  async getSoldeClient({ commit }, payload) {
    try {
      const params = {
        id: payload.id,
        type_partenaire: payload.type_partenaire
      };
      if (payload?.id_client) {
        params.id_client = payload.id_client;
      }
      const response = await axios.get(
        domain + '/api/reglements/get-solde-client',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_SOLDE_CLIENT', response.data);
      return true;
    } catch (error) {
      return false;
    }
  },
  async getSoldeEcheance({ commit }, payload) {
    try {
      const params = {
        id: payload.id,
        type_partenaire: payload.type_partenaire
      };
      if (payload?.id_client) {
        params.id_client = payload.id_client;
      }
      const response = await axios.get(
        domain + '/api/reglements/get-echeance-client',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_SOLDE_ECHEANCE_CLIENT', response.data);
      return true;
    } catch (error) {
      return false;
    }
  },
  async fetachDataWithReglementId({ commit }, payload) {
    try {
      const response = await axios.get(
        `${domain}/api/reglements/payment/${payload.payment_id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error.response.error };
    }
  },
  async getAmountNonLettrer({ commit }, payload) {
    commit('SET_AMOUNT_NON_LITTRER_LOADER', true);
    commit('SET_AMOUNT_NON_LITTRER', null);

    try {
      const params = {
        filiale_id: payload.filiale_id,
        type_partenaire: payload.type_partenaire
      };
      if (payload?.id_client) {
        params.id_client = payload.id_client;
      }
      const response = await axios.get(
        domain + '/api/reglements/amount-non-lettrer',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_AMOUNT_NON_LITTRER', response.data);
      commit('SET_AMOUNT_NON_LITTRER_LOADER', false);
      return true;
    } catch (error) {
      commit('SET_AMOUNT_NON_LITTRER_LOADER', false);
      return false;
    }
  },
  resetMutationsReglements({ commit }) {
    commit('RESET_FACTURES_REGLEMENT', []);
    commit('SET_SOLDE_CLIENT', null);
    commit('SET_SOLDE_ECHEANCE_CLIENT', null);
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
