import axios from 'axios';
import domain from '@/environment';
const state = {
  listExerciceForSociete: [],
  sequanceOfFililale: [],
  sequenceLoader: false,
  sequanceErrors: null,
  loaderListExerciceForSociete: false
};
const getters = {
  getSequenceErrors: state => state.sequanceErrors,
  getSequenceLoader: state => state.sequenceLoader,
  getSequencesOfFiliale: state => state.sequanceOfFililale,
  getLoaderListExerciceForSociete: state => state.loaderListExerciceForSociete,
  getListExerciceForSociete: state => state.listExerciceForSociete
};
const mutations = {
  SET_LOADER_LIST_EXERCICE_OF_FILIALE(state, payload) {
    state.loaderListExerciceForSociete = payload;
  },
  SET_SEQUENCE_LOADER(state, payload) {
    state.sequenceLoader = payload;
  },
  SET_ERRORS_SEQUENCES(state, payload) {
    state.sequanceErrors = payload;
  },
  SET_LIST_SEQ_OF_FILIALE(state, payload) {
    state.sequanceOfFililale = payload.map(item => {
      return {
        ...item,
        begin_date: item.sequences[0].begin_date,
        end_date: item.sequences[0].end_date,
        loader: false
      };
    });
  },
  SET_LIST_EXERCICE_OF_FILIALE(state, payload) {
    state.listExerciceForSociete = payload;
  }
};
const actions = {
  async addSequanceForSociete({ commit }, payload) {
    const formData = new FormData();
    if (payload?.sequences_factures != null) {
      payload.sequences_factures.sequences.forEach((sequence, index) => {
        const sequencePrefix = `sequences_factures[${index}]`;
        formData.append(`${sequencePrefix}[id]`, sequence.id);
        formData.append(`${sequencePrefix}[sequence]`, sequence.sequence);
        formData.append(`${sequencePrefix}[begin_date]`, sequence.begin_date);
        formData.append(`${sequencePrefix}[end_date]`, sequence.end_date);
        formData.append(`${sequencePrefix}[num_length]`, sequence.num_length);
        formData.append(`${sequencePrefix}[begin_from]`, sequence.begin_from);
        formData.append(`${sequencePrefix}[last_num]`, sequence.last_num);
        formData.append(`${sequencePrefix}[type]`, sequence.type);
        formData.append(`${sequencePrefix}[index]`, sequence.index);
      });
    }
    if (payload?.id != null) {
      formData.append('id', payload.id);
    }
    try {
      const response = await axios.post(
        domain + '/api/filiale/addSequence',
        formData,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      return true;
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_ERRORS_SEQUENCES', data);
      } else if (error?.response?.data?.error) {
        commit('SET_ERRORS_SEQUENCES', error.response.data.error);
      } else {
        commit('SET_ERRORS_SEQUENCES', 'Une erreur est survenue');
      }
      return false;
    }
  },
  async addExerciceFiliale({ commit }, payload) {
    commit('SET_LOADER_LIST_EXERCICE_OF_FILIALE', true);

    try {
      const response = await axios.post(
        domain + `/api/filiale/generate_sequence_form_exercice`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_SEQ_OF_FILIALE', response.data.data);
      commit('SET_LOADER_LIST_EXERCICE_OF_FILIALE', false);
      return { succes: true };
    } catch (error) {
      if (error?.response?.data) {
        commit('SET_LOADER_LIST_EXERCICE_OF_FILIALE', false);
        return { succes: false, error: error?.response?.data.errors.exercice };
      } else {
        commit('SET_LOADER_LIST_EXERCICE_OF_FILIALE', false);
        return { succes: false, error: 'Une erreur est survenue' };
      }
    }
  },
  async fetchExerciceForSociete({ commit }, payload) {
    commit('SET_LOADER_LIST_EXERCICE_OF_FILIALE', true);
    commit('SET_LIST_EXERCICE_OF_FILIALE', []);

    try {
      const response = await axios.get(
        domain + `/api/filiale/used_exercices/${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_EXERCICE_OF_FILIALE', response.data.data);
      commit('SET_LOADER_LIST_EXERCICE_OF_FILIALE', false);
      return true;
    } catch (error) {
      commit('SET_LOADER_LIST_EXERCICE_OF_FILIALE', false);
      return false;
    }
  },
  async getAllSequancesOfFiliale({ commit }, payload) {
    commit('SET_SEQUENCE_LOADER', true);
    try {
      const response = await axios.get(
        domain + `/api/filiale/sequences?id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_SEQ_OF_FILIALE', response.data.data);
      commit('SET_SEQUENCE_LOADER', false);
      return true;
    } catch (error) {
      if (error?.response?.data) {
        commit('SET_ERRORS_SEQUENCES', error.response.data);
      } else {
        commit('SET_ERRORS_SEQUENCES', 'Une erreur est survenue');
      }
      commit('SET_SEQUENCE_LOADER', false);
      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
