import axios from 'axios';
import domain from '@/environment';

const state = {
  loaderRibFiliale: false,
  errorRibFiliale: null,
  ribOfFiliale: []
};
const getters = {
  getErrorsRibs: state => state.errorRibFiliale,
  getRibLoader: state => state.loaderRibFiliale,
  getRibOfFiliale: state => state.ribOfFiliale
};
const mutations = {
  SET_RIBS_LOADER(state, payload) {
    state.loaderRibFiliale = payload;
  },
  SET_RIBS_ERRORS(state, payload) {
    state.errorRibFiliale = payload;
  },
  SET_LIST_RIBS_OF_FILIALS(state, payload) {
    state.ribOfFiliale = payload;
  },
};

const actions = {
  async getAllRibOfFiliale({ commit }, payload) {
    commit('SET_RIBS_LOADER', true);
    try {
      const response = await axios.get(
        domain + `/api/filiale/all-ribs?id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_RIBS_OF_FILIALS', response.data.data);
      commit('SET_RIBS_LOADER', false);
      return true;
    } catch (error) {
      if (error?.response?.data) {
        commit('SET_RIBS_ERRORS', error.response.data.message);
      } else {
        commit('SET_RIBS_ERRORS', 'Une erreur est survenue');
      }
      commit('SET_RIBS_LOADER', false);
      return false;
    }
  },
  async addRibToFiliale({ commit }, payload) {
    commit('SET_RIBS_ERRORS', null);
    const params = {
      filiale_id: payload.filiale_id,
      swift: payload.swift,
      iban: payload.iban,
      bank_id: payload.bank_id,
      code_bank: payload.code_bank,
      code_guichet: payload.code_guichet,
      num_compte: payload.num_compte,
      cle_rib: payload.cle_rib,
      familles: payload.familles
    };
    try {
      const response = await axios.post(
        domain + `/api/filiale/add-rib`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_RIBS_OF_FILIALS', response.data.data);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_RIBS_ERRORS', error.response.data.error);
      } else {
        commit('SET_RIBS_ERRORS', ['Une erreur est survenue']);
      }
      commit('SET_RIBS_LOADER', false);

      return false;
    }
  },
  async updateRibFiliale({ commit }, payload) {
    commit('SET_RIBS_ERRORS', null);
    const params = {
      id: payload.id,
      swift: payload.swift,
      iban: payload.iban,
      bank_id: payload.bank_id,
      favori: payload.favori,
      blocked: payload.blocked,
      code_bank: payload.code_bank,
      code_guichet: payload.code_guichet,
      num_compte: payload.num_compte,
      cle_rib: payload.cle_rib,
    };
    const existe = payload.familles.some(item => item.hasOwnProperty('id'));
    params.familles = existe
      ? payload.familles.map(item => item.famille)
      : payload.familles;

    try {
      const response = await axios.post(
        domain + `/api/filiale/edit-rib`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_RIBS_OF_FILIALS', response.data.data);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_RIBS_ERRORS', error.response.data.error);
      } else {
        commit('SET_RIBS_ERRORS', ['Une erreur est survenue']);
      }

      return false;
    }
  },

  resteModalOfRibs({ commit }, payload) {
    commit('SET_LIST_RIBS_OF_FILIALS', []);
    commit('SET_RIBS_ERRORS', null);
  }
};

export default {
  state,
  mutations,
  getters,
  actions
};
