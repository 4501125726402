import axios from 'axios';
import domain from '@/environment';
export default {
  state: {
    mails: [],
    errorMailFiliale: null,
    loaderMailFiliale: false,
    errorRemoveMail: null,
    updateMailError: null
  },
  getters: {
    getListMailFiliale: state => state.mails,
    getLoaderMailFiliale: state => state.loaderMailFiliale,
    getErrorMailFiliale: state => state.errorMailFiliale
  },
  mutations: {
    SET_ALL_MAILS_FILIALE(state, payload) {
      state.mails = payload;
    },
    SET_MAILS_IS_LOADING(state, payload) {
      state.loaderMailFiliale = payload;
    },
    ADD_NEW_EXTRA_MAIL(state, payload) {
      state.mails.unshift(payload);
    },
    DELETE_EXTRA_MAILS(state, id) {
      let index = state.mails.findIndex(mail => mail.id == id);
      state.mails.splice(index, 1);
    },
    UPDATE_EXTRA_MAILS(state, payload) {
      state.mails.map(mail => {
        if (mail.id == payload.id) {
          return Object.assign({}, mail, payload);
        }
        return payload;
      });
    },
    SET_MAILS_FILIALE_ERROR(state, payload) {
      state.errorMailFiliale = payload;
    }
  },
  actions: {
    async getExtraMailFiliale({ commit }, payload) {
      commit('SET_MAILS_IS_LOADING', true);
      try {
        const response = await axios.get(
          `${domain}/api/filiale/extra-mails/${payload}`,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('SET_ALL_MAILS_FILIALE', response.data.data);
        commit('SET_MAILS_IS_LOADING', false);
        return true;
      } catch (error) {
        if (error?.response?.data?.errors) {
          commit('SET_MAILS_FILIALE_ERROR', error?.response?.data?.errors?.nom);
        } else {
          commit('SET_MAILS_FILIALE_ERROR', 'Une erreur est survenue');
        }
        commit('SET_MAILS_IS_LOADING', false);
      }
    },
    async addExtraMailFiliale({ commit }, payload) {
      commit('SET_MAILS_FILIALE_ERROR', null);
      try {
        const response = await axios.post(
          `${domain}/api/filiale/add_extra_mail`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('ADD_NEW_EXTRA_MAIL', response.data.data);
        return true;
      } catch (error) {
        if (error?.response?.data?.errors) {
          let data = [];
          for (var item in error.response.data.errors) {
            if (error.response.data.errors[item].length)
              data.push(...error.response.data.errors[item]);
          }
          commit('SET_MAILS_FILIALE_ERROR', data);
        } else if (error?.response?.data?.error) {
          commit('SET_MAILS_FILIALE_ERROR', error.response.data.error);
        } else {
          commit('SET_MAILS_FILIALE_ERROR', 'Une erreur est survenue');
        }
        return false;
      }
    },
    async updateExtraMailFiliale({ commit }, payload) {
      commit('SET_MAILS_FILIALE_ERROR', null);
      try {
        let response = await axios.put(
          `${domain}/api/filiale/update-extra-mails/${payload.id}`,
          payload,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('UPDATE_EXTRA_MAILS', response.data.data);
        return true;
      } catch (error) {
        if (error?.response?.data?.errors) {
          let data = [];
          for (var item in error.response.data.errors) {
            if (error.response.data.errors[item].length)
              data.push(...error.response.data.errors[item]);
          }
          commit('SET_MAILS_FILIALE_ERROR', data);
        } else if (error?.response?.data?.error) {
          commit('SET_MAILS_FILIALE_ERROR', error.response.data.error);
        } else {
          commit('SET_MAILS_FILIALE_ERROR', 'Une erreur est survenue');
        }
      }
    },
    async deleteExtraMailFiliale({ commit }, id) {
      commit('SET_MAILS_FILIALE_ERROR', null);
      try {
        const response = await axios.delete(
          `${domain}/api/filiale/delete_extra_mail/${id}`,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('DELETE_EXTRA_MAILS', id);
        return true;
      } catch (error) {
        if (error && error.response && error.response.data.error) {
          commit('SET_MAILS_FILIALE_ERROR', error.response.data.error);
        } else {
          commit('SET_MAILS_FILIALE_ERROR', 'Une erreur est servenue');
        }
        return false;
      }
    },
    resetExtraMailFiliale({ commit }, payload) {
      commit('SET_MAILS_FILIALE_ERROR', null);
    }
  }
};
