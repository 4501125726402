export default class FacturePixel {
  constructor(data) {
    this.check = false;
    this.showDetails = false;
    this.FactureAvoir = data.FactureAvoir;
    if (data.FactureAvoir.length == 0) {
      this.avoir_id = null;
    } else {
      this.avoir_id = data.FactureAvoir[0].id;
    }
    this.organisme = data.organisme;
    this.lot = data.lot;
    this.modifiable = data.modifiable;
    this.acheteur = data.acheteur;
    this.categorie = data.categorie;
    this.comptabilise = data.comptabilise;
    this.date_creation = data.date_creation;
    this.etat = data.etat;
    this.famille = data.famille;
    this.id = data.id;
    this.id_acheteur = data.id_acheteur;
    this.id_vendeur = data.vendeur_id;
    this.net_ht = data.net_ht;
    this.num = data.num;
    this.statut_client = data.statut_client;
    this.total_ht = data.total_ht;
    this.total_net_ht = data.total_net_ht;
    this.total_pourcentage_tva = data.total_pourcentage_tva;
    this.total_reduction = data.total_reduction;
    this.total_ttc = data.total_ttc;
    this.total_tva = data.total_tva;
    this.ttc = data.ttc;
    this.type = data.type;
    this.type_avoir = data.type_avoir;
    this.user_id = data.user_id;
    this.vendeur = data.vendeur;
    this.filiale_id = data.filiale_id;
    this.montant_regle = data.montant_regle;
    this.client_id = data.client_id;
    this.client = data.acheteur;
    this.reste = data.reste;
    this.belongsTo = data.belongsTo;
    this.vendeur_id = data.vendeur_id;
    this.objet = data.objet;
    this.produits = data.produits;
    this.nom_prenom_client = data.nom_prenom_client;
    this.reste_charge = data.reste_charge;
  }
  static create(data) {
    return new FacturePixel(data);
  }
}
