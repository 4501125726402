import axios from 'axios';
import domain from '@/environment';
import typeSociete from '../../models/typeSocite';
let cancelRequestTypesSocietes=null
const state = {
  typeSociete: [],
  typeSocieteLoading: false,
  typeSocieteError: null,
  categoriesDetailsLoading: false,
  totalRowCategories: 0,
  totalRowsTypeSte: 0
};
const getters = {
  getTypeSociete: state => state.typeSociete,
  getTypeSocieteLoading: state => state.typeSociteLoading,
  getTypeSocieteError: state => state.typeSociteError,
  getTypeSocieteDetailsLoading: state => state.categoriesDetailsLoading,
  getTotalRowTypeSociete: state => state.totalRowCategories,
  getTotalRowsTypeSte: state => state.totalRowsTypeSte
};
const mutations = {
  ADD_NEW_TYPE_STE(state, payload) {
    state.typeSociete.unshift(typeSociete.create(payload));
  },
  SET_ALL_TYPE_STE(state, payload) {
    state.typeSociete = payload.map(item => typeSociete.create(item));
  },
  DELETE_TYPE_STE(state, payload) {
    const index = state.typeSociete.findIndex(
      element => element.id === payload.id
    );
    state.typeSociete.splice(index, 1);
  },
  UPDATE_TYPE_STE(state, payload) {
    payload.type.update(payload.response);
  },
  SET_TYPE_STE_ERROR(state, payload) {
    state.typeSociteError = payload;
  },
  SET_TYP_STE_LOADING(state, payload) {
    state.typeSocieteLoading = payload;
  },
  SET_TOTAL_ROWS_TYPE_STE(state, payload) {
    state.totalRowsTypeSte = payload;
  }
};
const actions = {
  async fetchAllTypeSociete({ commit }, payload) {
    commit('SET_TYPE_STE_ERROR', null);
    commit('SET_TYP_STE_LOADING', true);
    let params = {};
    if (payload?.page != null) {
      params.page = payload.page;
    }
    if (payload?.per_page != null) {
      params.per_page = payload.per_page;
    }
    if (payload?.type != null && payload?.type != '') {
      params.type = payload.type;
    }
    try {
      if (cancelRequestTypesSocietes) {
        cancelRequestTypesSocietes.cancel('cancel-request');
      }
      cancelRequestTypesSocietes = axios.CancelToken.source();
      const response = await axios.get(`${domain}/api/filiale/all-types`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: cancelRequestTypesSocietes.token
      });
      if (payload?.per_page != null) {
        commit('SET_ALL_TYPE_STE', response.data.data.data);
        commit('SET_TOTAL_ROWS_TYPE_STE', response.data.data.total);
      } else {
        commit('SET_ALL_TYPE_STE', response.data.data);
      }
      commit('SET_TYP_STE_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_TYPE_STE_ERROR', error?.response?.data?.errors?.nom);
      } else {
        commit('SET_TYPE_STE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_TYP_STE_LOADING', false);
    }
  },

  async addNewTypeSte({ commit }, payload) {
    commit('SET_TYPE_STE_ERROR', null);
    try {
      const response = await axios.post(
        `${domain}/api/filiale/add-type`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_NEW_TYPE_STE', response.data);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('SET_TYPE_STE_ERROR', error.response.data.errors.type);
      } else {
        if (error && error.response && error.response.data) {
          commit('SET_TYPE_STE_ERROR', error.response.data.error);
        } else {
          commit('SET_TYPE_STE_ERROR', ['Une erreur est survenue']);
        }
      }
    }
  },

  async deletetypeSte({ commit }, payload) {
    commit('SET_TYPE_STE_ERROR', null);
    commit('SET_TYP_STE_LOADING', true);
    try {
      const response = await axios.delete(
        `${domain}/api/filiale/delete-type/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_TYPE_STE', payload);
      commit('SET_TYP_STE_LOADING', false);

      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_TYPE_STE_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_TYPE_STE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_TYP_STE_LOADING', false);
    }
  },

  async updateTypeSte({ commit }, payload) {
    commit('SET_TYPE_STE_ERROR', null);
    commit('SET_TYP_STE_LOADING', true);
    const params = {
      id: payload.typeSte.id,
      type: payload.type
    };
    try {
      const response = await axios.post(
        `${domain}/api/filiale/edit-type`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_TYPE_STE', {
        response: response.data,
        type: payload.typeSte
      });
      commit('SET_TYP_STE_LOADING', false);

      return true;
    } catch (error) {
      if (error?.response?.data.error) {
        commit('SET_TYPE_STE_ERROR', error?.response?.data.error);
      } else {
        commit('SET_TYPE_STE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_TYP_STE_LOADING', false);
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
