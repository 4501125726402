import axios from 'axios';
import domain from '@/environment';
import categorieTh from '../../models/CategorieTh';
let cancelRequestCategories = null;
const state = {
  categories: [],
  categoriesIsLoading: false,
  categoriesError: null,
  categoriesDetailsLoading: false,
  totalRowCategories: 0
};
const getters = {
  getCategories: state => state.categories,
  getCategoriesIsLoading: state => state.categoriesIsLoading,
  categoriesError: state => state.categoriesError,
  getCategoriesDetailsLoading: state => state.categoriesDetailsLoading,
  getTotalRowCategories: state => state.totalRowCategories
};
const mutations = {
  ADD_NEW_CATEGORIE(state, payload) {
    state.categories.unshift(categorieTh.create(payload));
  },
  SET_ALL_CATEGORIES(state, payload) {
    state.categories = payload.map(item => categorieTh.create(item));
  },
  DELETE_CATEGORIE(state, payload) {
    const index = state.categories.findIndex(
      element => element.id === payload.id
    );
    state.categories.splice(index, 1);
  },
  UPDATE_CATEGORIE_PRODUIT(state, payload) {
    payload.categorie.update(payload.response);
  },
  SET_CATEGORIES_ERROR(state, payload) {
    state.categoriesError = payload;
  },
  SET_CATEGORIES_IS_LOADING(state, payload) {
    state.categoriesDetailsLoading = payload;
  },
  SET_TOTAL_ROWS_CATEGORIE(state, payload) {
    state.totalRowCategories = payload;
  }
};
const actions = {
  async fetchAllCategories({ commit }, payload) {
    const params = {};
    if (payload?.page) {
      params.page = payload.page;
    }
    if (payload?.per_page) {
      params.per_page = payload.per_page;
    }
    commit('SET_CATEGORIES_ERROR', null);
    commit('SET_CATEGORIES_IS_LOADING', true);
    try {
      if (cancelRequestCategories) {
        cancelRequestCategories.cancel('cancel-request');
      }
      cancelRequestCategories = axios.CancelToken.source();
      const response = await axios.get(`${domain}/api/libre/categories`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: cancelRequestCategories.token
      });
      commit(
        'SET_ALL_CATEGORIES',
        payload ? response.data.data : response.data
      );
      if (payload?.per_page && payload?.page) {
        commit('SET_TOTAL_ROWS_CATEGORIE', response.data.total);
      }
      commit('SET_CATEGORIES_IS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_CATEGORIES_ERROR', error?.response?.data?.errors?.nom);
      } else {
        commit('SET_CATEGORIES_ERROR', 'Une erreur est survenue');
      }
      commit('SET_CATEGORIES_IS_LOADING', false);
    }
  },

  async addNewCategorie({ commit }, payload) {
    commit('SET_CATEGORIES_ERROR', null);
    commit('SET_CATEGORIES_IS_LOADING', true);
    try {
      const response = await axios.post(
        `${domain}/api/libre/categorie`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_NEW_CATEGORIE', response.data);
      return true;
    } catch (error) {
      if (error?.response?.data) {
        commit('SET_CATEGORIES_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_CATEGORIES_ERROR', 'Une erreur est survenue');
      }
      commit('SET_CATEGORIES_IS_LOADING', false);
    }
  },

  async deleteCategory({ commit }, payload) {
    commit('SET_CATEGORIES_ERROR', null);
    commit('SET_CATEGORIES_IS_LOADING', true);
    try {
      const response = await axios.delete(
        `${domain}/api/libre/categorie/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_CATEGORIE', payload);
      commit('SET_CATEGORIES_IS_LOADING', false);

      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_CATEGORIES_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_CATEGORIES_ERROR', 'Une erreur est survenue');
      }
      commit('SET_CATEGORIES_IS_LOADING', false);
    }
  },

  async updateCategorie({ commit }, payload) {
    commit('SET_CATEGORIES_ERROR', null);
    commit('SET_CATEGORIES_IS_LOADING', true);
    const params = {
      id: payload.categorie.id,
      nom_categorie: payload.nom_categorie
    };
    try {
      const response = await axios.put(
        `${domain}/api/libre/categorie`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('UPDATE_CATEGORIE_PRODUIT', {
        response: response.data,
        categorie: payload.categorie
      });
      commit('SET_CATEGORIES_IS_LOADING', false);

      return true;
    } catch (error) {
      if (error?.response?.data.error) {
        commit('SET_CATEGORIES_ERROR', error?.response?.data.error);
      } else {
        commit('SET_CATEGORIES_ERROR', 'Une erreur est survenue');
      }
      commit('SET_CATEGORIES_IS_LOADING', false);
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
