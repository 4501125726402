import axios from 'axios';
import domain from '@/environment';
const prefixService = 'mensuel/service';
let requestGetService = null;

const state = {
  services: [],
  loadingService: false,
  errorService: [],
  userServiceService: [],
  ClientOnligne: [],
  totalRowService: 0
};
const getters = {
  services: state => state.services,
  loadingService: state => state.loadingService,
  errorService: state => state.errorService,
  usersMensuel: state => state.userServiceService,
  getClientOnligne: state => state.ClientOnligne,
  getTotalRowService: state => state.totalRowService
};
const mutations = {
  SET_SERVICES(state, payload) {
    state.services = payload;
  },
  ADD_NEW_SERVICE(state, payload) {
    state.services.unshift(payload);
  },
  DELETE_SERVICE(state, payload) {
    let index = state.services.findIndex(service => service.id == payload);
    state.services.splice(index, 1);
  },
  SET_SERVICES_LOADING(state, payload) {
    state.loadingService = payload;
  },
  SET_ERRORS_SERVICES(state, payload) {
    state.errorService = payload;
  },
  UPDATE_SERVICE(state, payload) {
    let services = state.services.map(service => {
      if (service.id === payload.id) {
        return { ...service, ...payload };
      }
      return service;
    });
    state.services = services;
  },
  SET_USERS_FILIALES_MENSUAL(state, payload) {
    state.userServiceService = payload;
  },
  SET_CLIENT_ONLIGNE(state, payload) {
    state.ClientOnligne = payload;
  },
  SET_TOTAL_ROWS_SERVICE(state, payload) {
    state.totalRowService = payload;
  }
};
const actions = {
  async getServices({ commit }, payload) {
    commit('SET_ERRORS_SERVICES', null);
    commit('SET_SERVICES_LOADING', true);
    let params = {};
    if (payload?.page != null) {
      params.page = payload.page;
    }
    if (payload?.perPage != null) {
      params.per_page = payload.perPage;
    }
    if (payload?.service != null && payload?.service != '') {
      params.service = payload.service;
    }
    try {
      if (requestGetService) {
        requestGetService.cancel('cancel-request');
      }
      requestGetService = axios.CancelToken.source();
      const response = await axios.get(`${domain}/api/mensuel/service/all`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: requestGetService.token
      });
      if (payload?.perPage != null) {
        commit('SET_SERVICES', response.data.data.data);
        commit('SET_TOTAL_ROWS_SERVICE', response.data.data.total);
      } else {
        commit('SET_SERVICES', response.data.data);
      }
      commit('SET_SERVICES_LOADING', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERRORS_SERVICES', error.response.data);
      } else if (error.message !== 'cancel-request') {
        commit('SET_ERRORS_SERVICES', 'Une erreur est survenue');
      }
      commit('SET_SERVICES_LOADING', false);
      return false;
    }
  },
  async getFilialeByUser({ commit }) {
    commit('SET_ERRORS_SERVICES', null);
    commit('SET_SERVICES_LOADING', true);
    try {
      const response = await axios.get(
        `${domain}/api/mensuel/get_filiales_by_user`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_USERS_FILIALES_MENSUAL', response.data);
      commit('SET_SERVICES_LOADING', false);
      return true;
    } catch (error) {
      commit('SET_ERRORS_SERVICES', ['Une erreur est servenue']);
      commit('SET_SERVICES_LOADING', false);
    }
  },
  async getClientOnligneUsers({ commit }) {
    commit('SET_ERRORS_SERVICES', null);
    commit('SET_SERVICES_LOADING', true);
    try {
      const response = await axios.get(
        `${domain}/api/Facture_sci/vision/client_online`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_CLIENT_ONLIGNE', response.data.data);
      commit('SET_SERVICES_LOADING', false);
      return true;
    } catch (error) {
      commit('SET_ERRORS_SERVICES', ['Une erreur est servenue']);
      commit('SET_SERVICES_LOADING', false);
    }
  },
  async createService({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/mensuel/service/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_NEW_SERVICE', response.data);
      return { success: true };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        return { success: false, error: data };
      } else {
        if (error && error.response && error.response.data) {
          return { success: false, error: error.response.data.error };
        } else {
          return { success: false, error: 'Une erreur est survenue' };
        }
      }
    }
  },
  async regenerateProformat({ commit }, payload) {
    commit('SET_SERVICES_LOADING', true);
    try {
      const response = await axios.post(
        domain + `/api/mensuel/regenerate_proforma`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_SERVICES_LOADING', false);
      return {
        succes: true,
        message: response.data.data
      };
    } catch (error) {
      commit('SET_SERVICES_LOADING', false);
      return { succes: false };
    }
  },
  async updateService({ commit }, payload) {
    commit('SET_ERRORS_SERVICES', null);
    try {
      const response = await axios.post(
        domain + `/api/${prefixService}/update`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_SERVICE', response.data);
      return { success: true };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        return { success: false, error: data };
      } else {
        if (error && error.response && error.response.data) {
          return { success: false, error: error.response.data.error };
        } else {
          return { success: false, error: 'Une erreur est survenue' };
        }
      }
    }
  },
  async deleteService({ commit }, payload) {
    try {
      const response = await axios.delete(
        domain + `/api/${prefixService}/delete/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_SERVICE', payload.id);
      return { success: true };
    } catch (error) {
      commit('SET_SERVICES_LOADING', false);
      return { success: false, error: error.response.data.error };
    }
  },
  resetCommit({ commit }) {
    commit('SET_ERRORS_SERVICES', null);
    commit('SET_SERVICES_LOADING', false);
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
