import configuration from './configuration';
export default class ConfigurationComptaRh {
  constructor(data) {
    this.id = data.id;
    this.vendeur = data.name;
    this.showingDetails = false ;
    this.confs = data.confs.map(conf => configuration.create(conf));
  }
  static create(data) {
    return new ConfigurationComptaRh(data);
  }
}
