import Echo from 'laravel-echo';
import store from '../store/index';
window.pusher = require('pusher-js');
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'a531dcd950d5a7bcdd70',
  encrypt: true,
  cluster: 'eu'
});
const getNotificationExportFile = function() {
  window.Echo.channel('notification').listen(
    '.user_' + store.state.User.id,
    function(e) {
      var newNotification = e.notification;
      store.commit('NEW_NOTIFICATION', newNotification);
      store.dispatch('fecthListFichierExporteCdg');
      if (e.notification.source == 'COTH') {
        store.dispatch('setNotifRefreshCompta', false);
      }
    }
  );
};

const destroyed = function() {
  window.Echo.leave('notification');
};
export default {
  getNotificationExportFile,
  destroyed
};
