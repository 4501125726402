import axios from 'axios';
import domain from '@/environment';
const state = {
  planTiers: [],
  planTiersLoader: false,
  planTiersErrors: null,
};
const getters = {
  getPlanTiersErrors: state => state.planTiersErrors,
  getPlanTiersLoader: state => state.planTiersLoader,
  getPlanTiers: state => state.planTiers
};
const mutations = {
  SET_PLAN_TIERS_LOADER(state, payload) {
    state.planTiersLoader = payload;
  },
  SET_ERRORS_PLAN_TIERS(state, payload) {
    state.planTiersErrors = payload;
  },
  SET_LIST_PLAN_TIERS_FILIEALS(state, payload) {
    state.planTiers = payload
  }
};
const actions = {
  async cudPlanTiersForSociete({ commit }, payload) {
    const formData = new FormData();
    formData.append('filiale', payload.id);
    if (payload?.planTiers) {
      payload.planTiers.forEach((plan, index) => {
        const planPrefix = `tiers[${index}]`;
        if (plan?.id != null) {
          formData.append(`${planPrefix}[id]`, plan.id);
        }
        formData.append(`${planPrefix}[client_id]`, plan.client_id);
        formData.append(`${planPrefix}[client]`, plan.client === null || plan.client === "null" ? null : plan.client);
        formData.append(`${planPrefix}[fournisseur]`, plan.fournisseur === null || plan.fournisseur === "null" ? null : plan.fournisseur);
        formData.append(`${planPrefix}[reste_charge]`, plan.reste_charge === null || plan.reste_charge === "null" ? null : plan.reste_charge);
        formData.append(`${planPrefix}[commission]`, plan.commission === null || plan.commission === "null" ? null : plan.commission);
        formData.append(`${planPrefix}[prime]`, plan.prime === null || plan.prime === "null" ? null : plan.prime);
        formData.append(`${planPrefix}[is_active]`, plan.is_active);
      });
    }

    try {
      const response = await axios.post(
        domain + '/api/comptabilite/createPlanComptableFiliale',
        formData,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      commit('SET_LIST_PLAN_TIERS_FILIEALS', response.data.data);
      return { succes: true, data: response.data.data };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_ERRORS_PLAN_TIERS', data);
      } else if (error?.response?.data?.error) {
        commit('SET_ERRORS_PLAN_TIERS', error.response.data.error);
      } else {
        commit('SET_ERRORS_PLAN_TIERS', 'Une erreur est survenue');
      }
      return { succes: false };
    }
  },
  async getPlanTiersOfFiliale({ commit }, payload) {
    commit('SET_PLAN_TIERS_LOADER', true);
    try {
      const response = await axios.get(
        domain + `/api/comptabilite/getPlanComptableByFiliale/${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('SET_LIST_PLAN_TIERS_FILIEALS', response.data.data);
      commit('SET_PLAN_TIERS_LOADER', false);
      return { succes: true, data: response.data.data };
    } catch (error) {
      if (error?.response?.data) {
        commit('SET_ERRORS_PLAN_TIERS', error.response.data);
      } else {
        commit('SET_ERRORS_PLAN_TIERS', 'Une erreur est survenue');
      }
      commit('SET_PLAN_TIERS_LOADER', false);
      return { succes: false };
    }
  },
  async updatePlanTiersFilialeWithXlsFile({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/comptabilite/generateDataFromFileXls`, payload,
        {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          },
        }
      );

      return { succes: true, data: response.data.data };
    } catch (error) {
      if (error?.response?.data?.error) {
        return { succes: false, error: error.response.data.error };
      } else {
        return { succes: false, error: 'Une erreur est survenue' };
      }
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
