import axios from 'axios';
import domain from '@/environment';
import GestionClientPassage from '../../models/GestionClientPassage';
let cancelRequestClientPassage = null;
const state = {
  listClient: [],
  clientIsError: null,
  clientIsLoading: false,
  totalRowsPassages: 0
};
const getters = {
  getListClientPassages: state => state.listClient,
  getClientIsError: state => state.clientIsError,
  getClientlsLoading: state => state.clientIsLoading,
  getTotalRowsPassages: state => state.totalRowsPassages
};
const mutations = {
  ADD_NEW_CLIENT(state, payload) {
    state.listClient.unshift(GestionClientPassage.create(payload));
  },
  SET_ALL_CLIENT(state, payload) {
    state.listClient = payload.map(client =>
      GestionClientPassage.create(client)
    );
  },
  SET_TOTAL_ROWS_PASSAGES(state, payload) {
    state.totalRowsPassages = payload;
  },
  DELETE_CLIENT(state, payload) {
    const index = state.listClient.findIndex(
      element => element.id === payload.id
    );
    state.listClient.splice(index, 1);
  },
  UPDATE_CLIENT(state, payload) {
    payload.clientPassage.update(payload.response);
  },
  SET_CLIENT_IS_ERROR(state, payload) {
    state.clientIsError = payload;
  },
  SET_CLIENT_IS_LOADING(state, payload) {
    state.clientIsLoading = payload;
  }
};
const actions = {
  async fetchAllTableClient({ commit }, payload) {
    const params = {};
    if (payload && payload.page) {
      params.page = payload.page;
    }
    if (payload && payload.per_page) {
      params.per_page = payload.per_page;
    }
    if (payload && payload.type) {
      params.type = payload.type;
    }
    if (payload && payload.search) {
      params.search = payload.search;
    }
    commit('SET_CLIENT_IS_ERROR', null);
    commit('SET_CLIENT_IS_LOADING', true);
    try {
      if (cancelRequestClientPassage) {
        cancelRequestClientPassage.cancel('cancel-request');
      }
      cancelRequestClientPassage = axios.CancelToken.source();
      const response = await axios.get(`${domain}/api/filiale/all_filiale`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: cancelRequestClientPassage.token
      });
      commit('SET_ALL_CLIENT', response.data.data.data);
      commit('SET_TOTAL_ROWS_PASSAGES', response.data.data.total);
      commit('SET_CLIENT_IS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_CLIENT_IS_ERROR', error?.response?.data?.errors?.nom);
      } else {
        commit('SET_CLIENT_IS_ERROR', 'Une erreur est survenue');
      }
      commit('SET_CLIENT_IS_LOADING', false);
    }
  },

  async addNewClientPassage({ commit }, payload) {
    commit('SET_CLIENT_IS_ERROR', null);
    commit('SET_CLIENT_IS_LOADING', true);
    const params = new FormData();
    params.append('name', payload.name);
    params.append('email', payload.email);
    params.append('telephone', payload.telephone);
    params.append('adresse', payload.adresse);
    params.append('type', 'passage');
    params.append('code_postal', payload.code_postal);
    params.append('ville', payload.ville);
    params.append('pays', payload.pays);
    if (payload && payload.tva_value) {
      params.append('tva_value', payload.tva_value);
    }
    if (payload && payload.siren_value) {
      params.append('siren_value', payload.siren_value);
    }
    if (payload && payload.description) {
      params.append('description', payload.description);
    }
    if (payload && payload.telephone_portable) {
      params.append('telephone_portable', payload.telephone_portable);
    }
    if (payload && payload.siret) {
      params.append('siret', payload.siret);
    }
    //ACHAT
    if (payload?.achat?.journal != null) {
      params.append('achat[journal]', payload?.achat.journal);
    }
    if (payload?.achat?.marchandise != null) {
      params.append('achat[marchandise]', payload?.achat.marchandise);
    }
    if (payload?.achat?.prestation != null) {
      params.append('achat[compte]', payload?.achat.prestation);
    }
    //VENTE
    if (payload?.vente?.journal != null) {
      params.append('vente[journal]', payload?.vente.journal);
    }
    if (payload?.vente?.marchandise != null) {
      params.append('vente[marchandise]', payload?.vente.marchandise);
    }
    if (payload?.vente?.prestation != null) {
      params.append('vente[compte]', payload?.vente.prestation);
    }
    //COMPTE GENERATE 
    if (payload?.customer != null) {
      params.append('customer', payload.customer);
    }
    if (payload?.supplier != null) {
      params.append('supplier', payload.supplier);
    }
    //IS DETAILED 
    params.append('is_detailed', payload.is_detailed);
    params.append('is_detailed_ttc', payload.is_detailed_ttc);
    params.append('is_detailed_reste_charge', payload.is_detailed_reste_charge);
    //PRIME 
    if (payload?.prime?.compte != null) {
      params.append('prime[compte]', payload.prime.compte);
    }
    if (payload?.prime?.journal != null) {
      params.append('prime[journal]', payload.prime.journal);
    }
    try {
      const response = await axios.post(
        `${domain}/api/filiale/create_filiale`,
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_NEW_CLIENT', response.data.data);
      commit('SET_CLIENT_IS_LOADING', false);
      return {
        success: true,
        response: response.data.data
      };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        return {
          success: false,
          error: data
        };
      } else {
        return {
          success: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  },

  async deleteClientPassage({ commit }, payload) {
    commit('SET_CLIENT_IS_ERROR', null);
    commit('SET_CLIENT_IS_LOADING', true);
    try {
      const response = await axios.get(
        `${domain}/api/filiale/delete_filiale/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_CLIENT', payload);
      commit('SET_CLIENT_IS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_CLIENT_IS_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_CLIENT_IS_ERROR', 'Une erreur est survenue');
      }
    }
  },
  async updateClientPassage({ commit }, payload) {
    commit('SET_CLIENT_IS_ERROR', null);
    commit('SET_CLIENT_IS_LOADING', true);
    let data = { ...payload.configurationClientToUpdate }
    data.vente.compte = data.vente.prestation
    data.achat.compte = data.achat.prestation
    try {
      const response = await axios.post(
        domain + `/api/filiale/edit_filiale`,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_CLIENT', {
        clientPassage: payload.oldConfigurationClientToUpdate,
        response: response.data.data
      });
      commit('SET_CLIENT_IS_LOADING', false);
      return {
        success: true
      };
    } catch (error) {
      if (error?.response?.data?.error) {
        let data = [];
        if (Array.isArray(error.response.data.error)) {
          for (var item in error.response.data.error) {
            if (error.response.data.error[item].length)
              data.push(...error.response.data.error[item]);
          }
        } else {
          data.push(error.response.data.error);
        }
        return {
          success: false,
          error: data
        };
      } else {
        return {
          success: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  },
  async errorManagerFiliale({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/filiale/error_manager_filiale`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return {
        success: true,
        response: response.data.data
      };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        return {
          success: false,
          error: data
        };
      } else {
        return {
          success: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
