export default class ProduitTh {
  constructor(data) {
    this.id = data.id;
    this.nom = data.nom;
    this.reference = data.reference;
    this.pu_ht = data.pu_ht;
    this.ttc = data.ttc;
    this.tva = data.tva;
    this.devise = data.devise;
    this.description = data.description;
    this.categorie_id = data.categorie_id;
    this.category = data.category;
    this.type = data.type;
  }
  update(data) {
    this.id = data.id;
    this.nom = data.nom;
    this.reference = data.reference;
    this.pu_ht = data.pu_ht;
    this.ttc = data.ttc;
    this.tva = data.tva;
    this.devise = data.devise;
    this.description = data.description;
    this.categorie_id = data.categorie_id;
    this.category = data.category;
    this.type = data.type;
  }
  static create(data) {
    return new ProduitTh(data);
  }
}
