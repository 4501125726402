export default class FactureMaster2 {
  constructor(data) {
    this.check = false;
    this.FactureAvoir = data.FactureAvoir;
    if (data.FactureAvoir.length == 0) {
      this.avoir_id = null;
    } else {
      this.avoir_id = data.FactureAvoir[0].id;
    }
    this.modifiable = data.modifiable;
    this.acheteur = data.acheteur;
    this.categorie = data.categorie;
    this.comptabilise = data.comptabilise;
    this.config_id = data.config_id;
    this.confirme = data.confirme;
    this.date_creation = data.date_creation;
    this.date_paiement = data.date_paiement;
    this.etat = data.etat;
    this.famille = data.famille;
    this.id = data.id;
    this.id_acheteur = data.id_acheteur;
    this.id_vendeur = data.id_vendeur;
    this.net_ht = data.net_ht;
    this.sous_objet = data.sous_objet;
    this.num = data.num;
    this.num_proforma = data.num_proforma;
    this.numero_document_corrige = data.numero_document_corrige;
    this.statut_client = data.statut_client;
    this.total_ht = data.total_ht;
    this.total_net_ht = data.total_net_ht;
    this.total_pourcentage_tva = data.total_pourcentage_tva;
    this.total_reduction = data.total_reduction;
    this.total_ttc = data.total_ttc;
    this.total_tva = data.total_tva;
    this.ttc = data.ttc;
    this.type = data.type;
    this.type_avoir = data.type_avoir;
    this.user_id = data.user_id;
    this.vendeur = data.vendeur;
    this.filiale_id = data.filiale_id;
    this.template_id = data.template_id;
    this.reglements = data.reglements;
    this.date_echeance = data.date_echeance;
    this.montant_regle = data.montant_regle;
    this.client_id = data.client_id;
    this.client = data.acheteur;
    this.reste = data.reste;
    this.belongsTo = data.belongsTo;
    this.vendeur_id = data.vendeur_id;
    this.id_vendeur = data.vendeur_id;
    this.historique = data.logs;
  }
  update(data) {
    this.check = false;
    this.client_id = data.client_id;
    this.FactureAvoir = data.FactureAvoir;
    this.acheteur = data.acheteur;
    this.categorie = data.categorie;
    this.comptabilise = data.comptabilise;
    this.config_id = data.config_id;
    this.confirme = data.confirme;
    this.date_creation = data.date_creation;
    this.payment_date = data.payment_date;
    this.etat = data.etat;
    this.famille = data.famille;
    this.id = data.id;
    this.id_acheteur = data.id_acheteur;
    this.net_ht = data.net_ht;
    this.num = data.num;
    this.num_proforma = data.num_proforma;
    this.numero_document_corrige = data.numero_document_corrige;
    this.statut_client = data.statut_client;
    this.total_ht = data.total_ht;
    this.total_net_ht = data.total_net_ht;
    this.total_pourcentage_tva = data.total_pourcentage_tva;
    this.total_reduction = data.total_reduction;
    this.total_ttc = data.total_ttc;
    this.total_tva = data.total_tva;
    this.sous_objet = data.sous_objet;
    this.ttc = data.ttc;
    this.type = data.type;
    this.type_avoir = data.type_avoir;
    this.user_id = data.user_id;
    this.vendeur = data.vendeur;
    if (data.FactureAvoir.length == 0) {
      this.avoir_id = null;
    } else {
      this.avoir_id = data.FactureAvoir[0].id;
    }
    this.modifiable = data.modifiable;
    this.vendeur_id = data.vendeur_id;
    this.id_vendeur = data.vendeur_id;
    this.historique = data.logs;
  }
  updateDataReglement(data) {
    this.reste = data.reste;
    this.reglements = data.reglements;
    this.date_echeance = data.date_echeance;
    this.montant_regle = data.montant_regle;
    this.date_paiement = data.date_paiement;
    this.payment_type = data.payment_type;
    this.etat = data.etat;
    this.statut_client = data.statut_client;
  }
  updateAvoir(data) {
    this.FactureAvoir = data.FactureAvoir;
    this.client_id = data.client_id;
    this.vendeur = data.vendeur;
    this.vendeur_id = data.vendeur_id;
    this.statut_client = data.statut_client;
    this.etat = data.etat;
    this.reglements = data.reglements;
    this.reste = data.reste;
    this.montant_regle = data.montant_regle;
    this.reste = data.reste;
    this.reglements = data.reglements;
    this.date_echeance = data.date_echeance;
    this.montant_regle = data.montant_regle;
    this.date_paiement = data.date_paiement;
    this.payment_type = data.payment_type;
    this.etat = data.etat;
    this.statut_client = data.statut_client;
  }
  static create(data) {
    return new FactureMaster2(data);
  }
}
